import { data, sharedHelper, helpers } from "@crewos/shared";

import { faArchive } from "@fortawesome/free-solid-svg-icons";

import PayrollReport from "./pages/PayrollReport";
import SmartReports from "./pages/SmartReports";
import LaborCostReport from "./pages/LaborCostReport";
import { useGetSmartReports } from "./api/Reports.hooks";

import Settings from "./pages/settings/Settings";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const REPORTS = "REPORTS";
const REPORTS_PACKAGE = "reports";

const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";
const SMART_REPORTS_IN_SIDEBAR = "SMART_REPORTS_IN_SIDEBAR";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

// Routes
const routes = (
  smartReportsReadOnlyEnabled,
  smartReportsInSidebarEnabled,
  smartReports = []
) => [
  {
    name: "Reports",
    icon: faArchive,
    sidebar: true,
    path: "/reports",
    package: "reports",
    entries: [
      {
        path: `payroll`,
        name: "Payroll",
        sidebar: true,
        component: PayrollReport,
      },
      {
        path: "labor",
        name: "Labor Cost Report",
        sidebar: true,
        component: LaborCostReport,
      },
      {
        path: `smart`,
        name: `${smartReportsReadOnlyEnabled ? "Preset" : "Smart"} Reports`,
        sidebar: true,
        scopes: smartReportsInSidebarEnabled
          ? ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"]
          : ["IS_SUPER_ADMIN_USER"],
        component: SmartReports,
      },
      {
        path: `smart/:id`,
        sidebar: false,
        component: SmartReports,
      },
      ...smartReports
        .filter((report) => report.context === "GLOBAL")
        .map((smartReport) => ({
          path: `smart/${smartReport.id}`,
          name: smartReport.name,
          sidebar: true,
          component: SmartReports,
        })),
    ],
  },
  {
    sidebar: true,
    icon: null,
    name: "Reports Settings",
    path: `reports`,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "reports",
    category: "Reports",
    component: Settings,
    order: 1,
  },
];

const Routes = createRoutes(routes());

const setReportSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const isSuperAdmin = sharedHelper.userMeetsRole(userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const smartReportsReadOnlyEnabled =
    sharedHelper.isSettingEnabled(
      packages,
      REPORTS_PACKAGE,
      SMART_REPORTS_READ_ONLY
    ) && !isSuperAdmin;

  const smartReportsInSidebarEnabled = sharedHelper.isSettingEnabled(
    packages,
    REPORTS_PACKAGE,
    SMART_REPORTS_IN_SIDEBAR
  );

  const sidebarRoutes = routes(
    smartReportsReadOnlyEnabled,
    smartReportsInSidebarEnabled,
    userData.smartReports
  ).filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "reports");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: REPORTS, order: 6 },
    });
  }
};

export {
  Routes as ReportRoutes,
  setReportSidebarRoutes,
  SmartReports,
  useGetSmartReports,
};
