import React from "react";

import CrewWorkDayExpenses from "./CrewWorkDayExpenses";
import { CrewWorkDayCrew, useCrews } from "@crewos/crews";

const CrewWorkDay = ({ crewWorkDay }) => {
  const [crewsContext] = useCrews();

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );

  return crew ? (
    <CrewWorkDayCrew crew={crew}>
      <CrewWorkDayExpenses crewWorkDay={crewWorkDay} />
    </CrewWorkDayCrew>
  ) : null;
};

export default CrewWorkDay;
