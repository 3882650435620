import React, { useState, useEffect } from "react";

import {
  Label,
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
} from "reactstrap";

import { useCreateSmartReport } from "../api/Reports.hooks";

import { sharedHelper, components } from "@crewos/shared";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { TooltipItem, CustomCheckboxWithLabel } = components;

const SaveSmartReportModal = ({ report, workOrderId, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState();
  const [showSidebar, setShowSidebar] = useState();

  const doSubmit = async (evt) => {
    evt.preventDefault();
    const { columns, prompt, query } = report;
    await createReport({
      columns,
      description,
      name,
      prompt,
      query,
      showSidebar,
      workOrderId,
    });
  };

  const { mutate: createReport, data: createReportData } =
    useCreateSmartReport();

  useEffect(() => {
    if (createReportData) {
      sharedHelper.successToast(`Report saved`);
      onSubmit(createReportData.data);
    }
  }, [createReportData, onSubmit]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          <div className="d-flex align-items-center">
            <span>Save Smart Report</span>
            <TooltipItem
              className="font-size-regular fw-regular"
              id="context-tooltip"
              title={`${workOrderId ? "Work Order" : "Global"} Context`}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="text-primary text-warning ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="15"
              type="text"
              placeholder="Enter the report name..."
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Description</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="1000"
              type="textarea"
              placeholder="Enter the report description..."
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <div
              className="d-flex"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <CustomCheckboxWithLabel
                className="mt-2"
                checked={showSidebar}
                label="Sidebar Link"
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit" data-testid="submit-button">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SaveSmartReportModal;
