import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  useCreateWorkOrder,
  useUpdateWorkOrder,
} from "../api/WorkOrders.hooks";

import { components, sharedHelper } from "@crewos/shared";
import WorkOrderForm from "./WorkOrderForm";

import moment from "moment";

const { Loader } = components;

const TODAY = sharedHelper.formatDate(moment(), "YYYY-MM-DD");

const WorkOrderModal = ({
  workOrder = {
    startDate: TODAY,
    endDate: TODAY,
  },
  onClose,
  onSubmit,
}) => {
  const [workOrderData, setWorkOrderData] = useState(workOrder);

  const {
    isLoading: isLoadingUpdateWorkOrder,
    update: updateWorkOrder,
    data: updateWorkOrderData,
  } = useUpdateWorkOrder();

  const {
    isLoading: isLoadingCreateWorkOrder,
    mutate: createWorkOrder,
    data: createWorkOrderData,
  } = useCreateWorkOrder();

  useEffect(() => {
    if (updateWorkOrderData) {
      sharedHelper.successToast(`Work order saved`);
      onSubmit();
    }
  }, [updateWorkOrderData, onSubmit]);

  useEffect(() => {
    if (createWorkOrderData) {
      sharedHelper.successToast(`Work order created`);
      onSubmit();
    }
  }, [createWorkOrderData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!workOrderData.workOrderStatusId) {
      return sharedHelper.warningToast("Work order status is required");
    }
    if (!workOrderData.startDate) {
      return sharedHelper.warningToast("Start date is required");
    }
    if (!workOrderData.endDate) {
      return sharedHelper.warningToast("End date is required");
    }
    if (workOrderData.id) {
      await updateWorkOrder(workOrderData);
    } else {
      await createWorkOrder(workOrderData);
    }
  };

  return (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {workOrderData.id ? "Edit" : "Create"} Work Order
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateWorkOrder || isLoadingUpdateWorkOrder ? (
            <Loader size="sm" />
          ) : (
            <WorkOrderForm
              workOrderData={workOrderData}
              setWorkOrderData={setWorkOrderData}
            />
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderModal;
