import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/user`;

export const useGetUsers = () => {
  return useApiGet(endpoint);
};

export const useCreateUser = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateUser = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (user) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteUser = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteUser = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteUser };
};
