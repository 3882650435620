import React, { useState, useRef, useEffect } from "react";

import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

import NoteModal from "./NoteModal";

import { components, sharedHelper } from "@crewos/shared";
import { useDeleteNote } from "../api/Notes.hooks";

const { TooltipItem, ConfirmationModal } = components;

const Note = ({ note, onRefresh }) => {
  const noteRef = useRef(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const { deleteNote } = useDeleteNote();

  useEffect(() => {
    const noteContainer = noteRef.current;
    if (noteContainer.clientHeight > 70) {
      setIsOverflowing(true);
    } else {
      setIsOverflowing(false);
    }
  }, [noteRef]);

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(false);
        await deleteNote(note.id);
        sharedHelper.successToast("Note deleted");
        onRefresh();
      },
      onClose: () => {
        setConfirmationModal(false);
      },
      title: "Delete Note",
      body: `Are you sure you want to delete the note?`,
      confirmColor: "primary",
      confirmText: "Delete",
    });
  };

  return (
    <div
      key={note.id}
      className="my-1 col-12 d-flex justify-content-between align-items-start px-0"
    >
      <div className="note px-3 py-2 w-100 d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <div
            ref={noteRef}
            className={`text-truncate text-wrap text-break ${
              isOverflowing && !seeMore ? "text-hidden" : "min-height-70"
            }`}
          >
            {note.content}
          </div>
          <small className="text-muted mt-2">{note.type}</small>
        </div>
        <div className="d-flex flex-shrink-0">
          {note.noteAuthor ? (
            <TooltipItem
              className="ms-2"
              id={`${note.id}-tooltip`}
              title={`Created by ${note.noteAuthor.firstName} ${note.noteAuthor.lastName}`}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faInfo}
                className="ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          ) : null}
          <div className="d-flex">
            {isOverflowing ? (
              <Button
                color="none"
                size="sm"
                className="text-primary ms-2"
                onClick={() => setSeeMore(!seeMore)}
              >
                See {seeMore ? "Less" : "More"}
              </Button>
            ) : null}
            <Button
              color="none"
              size="sm"
              className="text-primary ms-2"
              onClick={() => setEditNoteModal(true)}
            >
              Edit
            </Button>
            <Button
              color="none"
              size="sm"
              className="text-danger ms-2"
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      {editNoteModal && (
        <NoteModal
          onSubmit={() => {
            setEditNoteModal(false);
            sharedHelper.successToast("Note saved");
            onRefresh();
          }}
          onClose={() => {
            setEditNoteModal(false);
          }}
          noteId={note.id}
        />
      )}
      {confirmationModal && <ConfirmationModal {...confirmationModal} />}
    </div>
  );
};

export default Note;
