import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button } from "reactstrap";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    minWidth: "230px",
    backgroundColor: "transparent",
  }),
};

const animatedComponents = makeAnimated();

const SelectAdvance = ({
  options = [],
  isLoading = false,
  placeholder,
  noOptionsMessage = "No data found",
  selected = null,
  onSelected,
  isMulti,
  className = "",
  wrapperClassName = "",
  onSearch, // Nueva prop para manejar la búsqueda
  ...otherProps
}) => {
  const [recordSearch, setRecordSearch] = React.useState("");
  const [record, setRecord] = React.useState(selected);

  const handleSearch = React.useCallback(
    (text) => {
      setRecordSearch(text);
      onSearch(text); // Llamamos a la función de búsqueda pasada como prop
    },
    [onSearch]
  );

  const handleChange = React.useCallback(
    (selectedOption) => {
      setRecord(selectedOption);
      onSelected(selectedOption);
    },
    [onSelected]
  );

  return (
    <div className={`d-flex align-items-center ${wrapperClassName}`}>
      <Select
        isMulti={isMulti}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        classNamePrefix="react-select"
        className={`text-start bg-white px-0 react-select ${className}`}
        styles={style}
        options={options}
        closeMenuOnSelect={true}
        components={animatedComponents}
        isSearchable
        isClearable
        inputValue={recordSearch}
        value={record}
        onInputChange={handleSearch}
        isLoading={isLoading}
        onChange={handleChange}
        {...otherProps}
      />
      {isMulti ? (
        <Button
          className="ms-2"
          size="sm"
          onClick={() =>
            handleChange(options.length === record.length ? [] : options)
          }
          color="primary"
        >
          {options.length === record.length ? "None" : "All"}
        </Button>
      ) : null}
    </div>
  );
};

export default React.memo(SelectAdvance);
