import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";

import { layouts } from "@crewos/shared";

import SignIn from "../pages/auth/signIn/SignIn";

import { SettingsWrapper, settingsRoutes } from "./SettingsRoutes";

const { AuthLayout } = layouts;

const BaseRoutes = ({ children }) => {
  return (
    <Router basename={"/"}>
      <Routes>
        <Route
          path={"/auth/sign-in"}
          element={
            <AuthLayout>
              <SignIn />
            </AuthLayout>
          }
        />
        <Route path={""} element={<Navigate to="/workorders" />} />
        {children}
      </Routes>
    </Router>
  );
};

export { BaseRoutes, SettingsWrapper, settingsRoutes };
