import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";

import DatePicker from "react-datepicker";

import { crewHelper, useCrews } from "@crewos/crews";

import moment from "moment";

import { useCreateWorkTime } from "../api/WorkTimes.hooks";

import { components, data, sharedHelper } from "@crewos/shared";
import CrewMembers from "./CrewMembers";

const { useAuth, useWorkOrderDetails } = data;

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";
const WORK_TIMES_PACKAGE = "worktimes";

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const CREWS_PACKAGE = "crews";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const { Loader } = components;

const AddWorkTimeModal = ({ onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [authContext] = useAuth();
  const [crewsContext] = useCrews();
  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);

  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [timeToAdd, setTimeToAdd] = useState({});
  const [timeTypeToAdd, setTimeTypeToAdd] = useState({});

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    WORK_TIMES_PACKAGE,
    START_END_WORK_TIME_SETTING
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    isLoading: isLoadingCreateWorkTime,
    mutate: createWorkTime,
    data: createWorkTimeData,
  } = useCreateWorkTime();

  useEffect(() => {
    if (createWorkTimeData) {
      onSubmit();
    }
  }, [createWorkTimeData, onSubmit]);

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const doSubmit = async (event) => {
    event.preventDefault();
    const employees = Object.keys(timeToAdd).map((employeeId) => {
      const date = moment(selectedDate);
      const data = {
        workTimeTypeId: timeTypeToAdd[employeeId],
        date: date.format("YYYY-MM-DD"),
        employeeId,
        crewId: crew.id,
      };
      if (startEndTimeSettingEnabled) {
        const { startTime, endTime } = timeToAdd[employeeId];
        data.startTime = startTime;
        data.endTime = endTime;
      } else {
        data.hours = timeToAdd[employeeId];
      }
      return data;
    });
    if (!employees.length) {
      return sharedHelper.warningToast("Add time to at least one employee");
    }
    await createWorkTime({
      employees,
    });
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  useEffect(() => {
    setTimeToAdd({});
    setTimeTypeToAdd({});
    return () => setTimeToAdd({}) && setTimeTypeToAdd({});
  }, [crew, selectedDate]);

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Add Work Time
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateWorkTime ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={onSelectedDate}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>{directAssignationEnabled ? "Employee" : "Crew"}</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                {crewsWithLeadForDate.length ? (
                  <select
                    className="form-control-redesign px-0"
                    required={true}
                    type="select"
                    name="crewSelect"
                    id="crewSelect"
                    data-testid={`${
                      directAssignationEnabled ? "employee" : "crew"
                    }-select`}
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crew = crewsContext.crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>
                      Select a
                      {directAssignationEnabled ? "n Employee" : " Crew"}
                    </option>
                    {crewsWithLeadForDate.map((crew) => {
                      const lead = crewHelper.getCrewLead(
                        employeeCrewPastDatesEnabled,
                        crew
                      );
                      return (
                        <option key={crew.id} value={crew.id}>
                          {`${lead.employee.firstName} ${lead.employee.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="text-muted small">
                    No crews for selected date
                  </div>
                )}
              </FormGroup>
            </>
          )}
          {crew &&
          selectedDate &&
          crewsWithLeadForDate.find((c) => c.id === crew.id) ? (
            <CrewMembers
              selectedDate={selectedDate}
              crew={crew}
              timeToAdd={timeToAdd}
              timeTypeToAdd={timeTypeToAdd}
              employeeEnabled={employeeEnabled}
              setTimeToAdd={setTimeToAdd}
              setTimeTypeToAdd={setTimeTypeToAdd}
              setEmployeeEnabled={setEmployeeEnabled}
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddWorkTimeModal;
