import React, { useState, useCallback } from "react";

import WorkPerformedWorkDay from "./WorkPerformedWorkDay";

import { useCrewWorkDays } from "../providers/crewWorkDaysProvider";

import { data, components, sharedHelper } from "@crewos/shared";

import WorkPerformedCards from "./WorkPerformedCards";

import { Button, Card, CardBody } from "reactstrap";
import AddWorkDayModal from "./AddWorkDayModal";

import axios from "axios";

const { WorkOrderDateSelector, Loader } = components;

const { useWorkOrderDetails } = data;

const WorkPerformed = ({ onEdit, onDelete }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [addWorkDayModal, setAddWorkDayModal] = useState();

  const onAddWorkDay = () => setAddWorkDayModal(true);

  const onDownloadPdf = useCallback(async () => {
    try {
      sharedHelper.loadingToast();

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/work-order/${workOrderDetails.workOrder.id}/report`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );

      const woNumber = workOrderDetails.workOrder.workOrderNumber;
      const customerName = workOrderDetails.workOrder.customer
        ? workOrderDetails.workOrder.customer.customerName
        : "No customer";

      const fileName = `${woNumber}-${customerName}.pdf`
        .replaceAll(" ", "_")
        .toLowerCase();

      sharedHelper.downloadFile(response.data, fileName);
    } catch (error) {
      sharedHelper.errorToast(error.message);
    } finally {
      sharedHelper.dismissLoadingToast();
    }
  }, [workOrderDetails]);

  return (
    <>
      <Card className="w-100 mb-3 px-0">
        <CardBody className="border-top-left-radius-0">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">Work Performed</h2>
            <div className="d-flex align-items-center">
              <Button color="primary" onClick={onDownloadPdf}>
                Download Report
              </Button>
              <Button color="primary" className="ms-2" onClick={onEdit}>
                Edit Info
              </Button>
              <Button color="primary" className="ms-2" onClick={onAddWorkDay}>
                Add Work Day
              </Button>
              <Button color="danger" className="ms-2" onClick={onDelete}>
                Delete
              </Button>
            </div>
          </div>
          <WorkPerformedCards />
          <WorkOrderDateSelector
            crewWorkDays={crewWorkDaysContext.crewWorkDays}
          />
        </CardBody>
      </Card>
      {workOrderDetails.loading ? (
        <Card className="w-100">
          <CardBody className="py-3">
            <Loader size="sm" />
          </CardBody>
        </Card>
      ) : !crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] ? (
        <Card className="w-100 px-0">
          <CardBody className="py-3 text-center">
            <div className="text-center text-muted small">
              No data to display
            </div>
          </CardBody>
        </Card>
      ) : (
        crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate].map(
          (crewWorkDay) => (
            <WorkPerformedWorkDay
              key={crewWorkDay.id}
              crewWorkDay={crewWorkDay}
            />
          )
        )
      )}
      {addWorkDayModal ? (
        <AddWorkDayModal onClose={() => setAddWorkDayModal(false)} />
      ) : null}
    </>
  );
};

export default WorkPerformed;
