import React, { useEffect, useState } from "react";

import { faSync, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  Input,
} from "reactstrap";

import { components, api, data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useGetSettings, useUpdateSetting } = api;

const { AdvanceTable, TooltipItem, ConfirmationModal } = components;

const { useAuth, AUTH_ACTIONS } = data;

const isTest = typeof jest !== "undefined";

const columns = (onChangeSettingStatus, onChangeSettingValue) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-primary"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => (
      <Input
        checked={rowData.row.status}
        onChange={(event) =>
          onChangeSettingStatus(rowData.row, event.currentTarget.checked)
        }
        type="switch"
        data-testid="status-switch"
        id={rowData.row.id}
        name={rowData.row.id}
        label={rowData.row.status ? "Enabled" : "Disabled"}
        className="cursor-pointer d-flex align-items-center small setting-switch"
      />
    ),
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { value, status } = rowData.row;
      if (!rowData.row.statusOnly) {
        return (
          <DebounceInput
            disabled={rowData.row.valueReadOnly}
            placeholder={`${rowData.row.valuePlaceholder}...`}
            className="form-control"
            data-testid={`setting-value-${rowData.row.id}`}
            maxLength="50"
            debounceTimeout={900}
            type="text"
            value={value}
            onChange={(e) => onChangeSettingValue(rowData.row, e.target.value)}
          />
        );
      }
      return status ? value || "-" : "-";
    },
  },
];

const Settings = () => {
  const [authContext, setAuthContext] = useAuth();

  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();
  const [confirmationModal, setConfirmationModal] = useState();

  const schedulesPackage = authContext.userData?.packages.find(
    (pkg) => pkg.name === "Schedules"
  );

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    if (schedulesPackage) {
      getSettings({
        packageId: schedulesPackage.id,
      });
    }
  }, [getSettings, schedulesPackage, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData, setSettings]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSettings,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      if (!isTest) {
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      }
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData, setAuthContext]);

  const onChangeSettingStatus = (setting, status) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        updateSettings({
          ...setting,
          status,
        });
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting status",
      body: `Are you sure you want to change this setting status?`,
      confirmText: "Update",
    });
  };

  const onChangeSettingValue = (setting, value) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        updateSettings({
          ...setting,
          value,
        });
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting value",
      body: `Are you sure you want to change this setting value?`,
      confirmText: "Update",
    });
  };

  const nonInternalSettings = settings.filter((setting) => !setting.isInternal);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card className="mb-3 w-100 box-shadow-none">
        <CardHeader className="pt-2 pb-3 d-flex align-items-center justify-content-between">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Schedules Settings</h2>
            <small className="text-muted ms-2 pt-1">({settings.length})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faSync} data-testid="refresh-button" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="overflow-x-auto p-4">
          <AdvanceTable
            columns={columns(onChangeSettingStatus, onChangeSettingValue)}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
              className: "mb-0",
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
    </Container>
  );
};

export default Settings;
