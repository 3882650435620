import { useGetCustomers } from "./api/Customers.hooks";

import { data, sharedHelper, helpers } from "@crewos/shared";

import Customers from "./pages/Customers";

import CustomerDetails from "./pages/CustomerDetails";

import { faHandshake } from "@fortawesome/free-solid-svg-icons";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const CUSTOMER = "CUSTOMER";

// Routes
const routes = [
  {
    path: "/customers",
    name: "Customers",
    package: "customers",
    icon: faHandshake,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    sidebar: true,
    component: Customers,
  },
  {
    path: `/customers/details/:customerId`,
    name: "Customers Details",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    package: "customers",
    component: CustomerDetails,
  },
];

const setCustomerSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "customers");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: CUSTOMER, order: 6 },
    });
  }
};

const Routes = createRoutes(routes);

export { useGetCustomers, Routes as CustomerRoutes, setCustomerSidebarRoutes };
