import React, { useState } from "react";

import { Button, Badge } from "reactstrap";

import EditWorkTimeModal from "./EditWorkTimeModal";

import { sharedHelper, data, components } from "@crewos/shared";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { useAuth, useWorkOrderDetails } = data;

const { TooltipItem } = components;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";
const WORK_TIMES_PACKAGE = "worktimes";

const CREWS_PACKAGE = "crews";

const EmployeeCrewRow = ({ employeeCrew, workTimes, onRefresh }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [editModal, setEditModal] = useState();

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    WORK_TIMES_PACKAGE,
    START_END_WORK_TIME_SETTING
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const isReadOnly = workOrderDetails.activeTab === JOB_DETAILS_TAB;

  const times = workTimes
    .sort((x, y) => y.workTimeType.order - x.workTimeType.order)
    .map((wt, index) => {
      const isInProgress = wt.startTime && !wt.endTime;
      const exceptionHours = sharedHelper.calculateExceptions(wt);
      const hours = isInProgress
        ? "In Progress"
        : sharedHelper.formatDecimal(wt.hours + exceptionHours);
      return (
        <div
          key={wt.workTimeType.id}
          className={`d-flex justify-content-${
            isReadOnly ? "end" : "center"
          } align-items-center flex-shrink-0`}
        >
          {index !== 0 ? <span className="mx-1">/</span> : null}
          <span>{`${hours} ${wt.workTimeType.shortName}`}</span>
          {startEndTimeSettingEnabled ? (
            <TooltipItem
              id={`${wt.workTimeType.id}-tooltip`}
              title={sharedHelper.timeEntryToolTipContent([wt], true)}
              opacity={1}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                data-testid={`${wt.workTimeType.id}-info-icon`}
                className="text-muted ms-1"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          ) : null}
        </div>
      );
    });

  return (
    <div className="d-flex align-items-center justify-content-between p-3 border-radius-default box-shadow-lg my-2">
      <div className="col-4 d-flex align-items-center flex-grow-1 pe-3">
        <span>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</span>
        <div className="text-muted d-flex align-items-center ms-2">
          {!directAssignationEnabled && employeeCrew.isLead ? (
            <Badge color="primary" className="me-2">
              Crew Lead
            </Badge>
          ) : null}
          <Badge color="primarylight" className="text-primary">
            {employeeCrew.role.name}
          </Badge>
        </div>
      </div>
      {workTimes.length ? (
        <>
          <div
            className={`d-flex col-6 justify-content-${
              isReadOnly ? "end" : "center"
            } align-items-center`}
          >
            {times}
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end">
              <Button
                size="sm"
                color="none"
                className="mx-2 text-primary"
                onClick={() => setEditModal(true)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={`col-6 text-${isReadOnly ? "end" : "center"} text-muted`}
          >
            No time
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end"></div>
          ) : null}
        </>
      )}
      {editModal ? (
        <EditWorkTimeModal
          workTimes={workTimes}
          employee={employeeCrew.employee}
          onClose={() => {
            setEditModal();
          }}
          onSubmit={() => {
            setEditModal();
            onRefresh();
          }}
        />
      ) : null}
    </div>
  );
};

export default EmployeeCrewRow;
