import React from "react";
import { useSidebar, ACTIONS } from "../providers/sidebarProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Hamburger = () => {
  const [sidebarContext, setSidebarContext] = useSidebar();

  return (
    <span
      className="sidebar-toggle d-xl-none"
      style={{ left: sidebarContext.isOpen ? 25 : 275 }}
      onClick={() => setSidebarContext({ action: ACTIONS.TOGGLE })}
    >
      <FontAwesomeIcon icon={faBars} size="lg" />
    </span>
  );
};

export default Hamburger;
