import React, { useEffect, useState } from "react";

import { Button, Label } from "reactstrap";

import { crewHelper, useCrewWorkDays } from "@crewos/crews";

import moment from "moment";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { sharedHelper } from "@crewos/shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useGetAllWorkTimeTypes } from "../api/WorkTimeTypes.hooks";

import { components, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const WORK_TIMES_PACKAGE = "worktimes";

const CREWS_PACKAGE = "crews";

const { Loader } = components;

const CrewMembers = ({
  selectedDate,
  crew,
  timeToAdd,
  employeeEnabled,
  timeTypeToAdd,
  setTimeTypeToAdd,
  setTimeToAdd,
  setEmployeeEnabled,
}) => {
  const [authContext] = useAuth();

  const [crewWorkDaysContext] = useCrewWorkDays();

  const [workTimeTypes, setWorkTimeTypes] = useState([]);

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    WORK_TIMES_PACKAGE,
    START_END_WORK_TIME_SETTING
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    isLoading: isLoadingGetWorkTimeTypes,
    get: getWorkTimeTypes,
    data: workTimeTypesData,
  } = useGetAllWorkTimeTypes();

  useEffect(() => {
    getWorkTimeTypes();
  }, [getWorkTimeTypes]);

  useEffect(() => {
    if (workTimeTypesData) {
      setWorkTimeTypes(workTimeTypesData);
    }
  }, [workTimeTypesData]);

  const workTimeTypeAlreadyAdded = (date, employeeId, workTimeTypeId) => {
    const crewWorkDay =
      crewWorkDaysContext.crewWorkDays[date]?.find(
        (cwd) => cwd.crewId === crew.id
      ) || [];
    const workTimes = crewWorkDay?.workTimes || [];
    const existingWorkTime = workTimes.find(
      (wt) =>
        wt.employeeId === employeeId && wt.workTimeTypeId === workTimeTypeId
    );
    return existingWorkTime;
  };

  const onTypeChange = (workTimeTypeId, employeeId) => {
    const isWorkTimeTypeAlreadyAdded = workTimeTypeAlreadyAdded(
      moment(selectedDate).format("YYYY-MM-DD"),
      employeeId,
      workTimeTypeId
    );
    if (isWorkTimeTypeAlreadyAdded) {
      const workTimeType = workTimeTypes.find(
        (wtt) => wtt.id === workTimeTypeId
      );
      const workTimeTypeName = workTimeType.name;
      return sharedHelper.warningToast(
        `The employee already has ${workTimeTypeName} hours added for the day. You can edit them using the edit button`
      );
    } else {
      return setTimeTypeToAdd({
        ...timeTypeToAdd,
        [employeeId]: workTimeTypeId,
      });
    }
  };

  const onHoursChange = (evt, employeeId) => {
    setTimeToAdd({
      ...timeToAdd,
      [employeeId]: evt.target.value,
    });
  };

  const lead = crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew);

  return (
    <div className="mt-3">
      <div className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default">
        {`${lead.employee.firstName} ${lead.employee.lastName}`}
      </div>
      {crew.employeeCrews
        .filter((employeeCrew) =>
          crewHelper.isEmployeeCrewActiveOnDate(
            employeeCrewPastDatesEnabled,
            employeeCrew,
            selectedDate
          )
        )
        .sort(sharedHelper.sortEmployeeCrew)
        .map((employeeCrew) => {
          const employee = employeeCrew.employee;
          const workTime = timeToAdd[employee.id] || {};
          let hours = timeToAdd[employee.id];

          const startTime = workTime.startTime
            ? moment(workTime.startTime).format("HH:mm")
            : null;

          const endTime = workTime.endTime
            ? moment(workTime.endTime).format("HH:mm")
            : null;

          if (startEndTimeSettingEnabled) {
            hours = (
              moment(workTime.endTime).diff(
                moment(workTime.startTime),
                "minutes"
              ) / 60
            ).toFixed(2);
          }

          return (
            <div
              key={employeeCrew.id}
              className="mt-1 d-flex justify-content-between align-items-start p-2 border-bottom col-12"
              tag="div"
            >
              <div className="d-flex flex-column align-items-start flex-grow-1">
                <small>{`${employee.firstName} ${employee.lastName}`}</small>
                <small className="text-muted text-left">{`${employeeCrew.role.name}`}</small>
              </div>
              <div className="ms-5 flex-grow-1 d-flex justify-content-end align-items-center align-self-center">
                {employeeEnabled[employee.id] ? (
                  <div className="col-12 px-0 d-flex flex-column align-items-start">
                    <div
                      className="d-flex justify-content-center align-items-center pb-2 cursor-pointer col-12 px-0 position-relative"
                      tag="div"
                    >
                      {isLoadingGetWorkTimeTypes ? (
                        <div className="min-width-50">
                          <Loader size="sm" />
                        </div>
                      ) : (
                        <div
                          className="d-flex align-items-start flex-column col-12 px-0"
                          tag="div"
                        >
                          <Label className="d-flex">
                            <small>Type</small>
                            <small className="ms-1 text-danger">*</small>
                          </Label>
                          <select
                            className="form-control-redesign px-0"
                            required={true}
                            type="select"
                            name="hoursTypeSelect"
                            id="hoursTypeSelect"
                            value={timeTypeToAdd[employee.id] || ""}
                            onChange={(event) =>
                              onTypeChange(event.target.value, employee.id)
                            }
                          >
                            <option value={""}>Select type...</option>
                            {workTimeTypes
                              .filter((wtt) =>
                                wtt.employeeRoleWorkTimeTypes.find(
                                  (item) =>
                                    item.employeeRoleId === employeeCrew.role.id
                                )
                              )
                              .map((wtt) => (
                                <option key={wtt.id} value={wtt.id}>
                                  {wtt.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 999,
                        }}
                        onClick={() =>
                          setEmployeeEnabled({
                            ...employeeEnabled,
                            [employee.id]: false,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="cursor-pointer text-danger bg-white"
                        />
                      </div>
                    </div>
                    {startEndTimeSettingEnabled ? (
                      <>
                        <div
                          className="d-flex align-items-start flex-column py-2 col-12 px-0"
                          tag="div"
                        >
                          <Label className="d-flex">
                            <small>Start Time</small>
                            <small className="ms-1 text-danger">*</small>
                          </Label>
                          <DebounceInput
                            debounceTimeout={900}
                            className="form-control-redesign px-0"
                            required={true}
                            step="60"
                            placeholder="Start Time"
                            type="time"
                            value={startTime || ""}
                            onChange={(event) => {
                              const mStartTime = moment(
                                event.target.value,
                                "HH:mm"
                              );
                              let endTime;
                              if (workTime.endTime) {
                                const mEndTime = moment(
                                  moment(workTime.endTime).format("HH:mm"),
                                  "HH:mm"
                                );
                                endTime = moment(selectedDate)
                                  .set("hours", mEndTime.get("hours"))
                                  .set("minutes", mEndTime.get("minutes"));
                                if (mEndTime.isBefore(mStartTime)) {
                                  endTime.add(1, "day");
                                }
                                endTime = endTime.toISOString();
                              }
                              const startTime = moment(selectedDate)
                                .set("hours", mStartTime.get("hours"))
                                .set("minutes", mStartTime.get("minutes"))
                                .toISOString();
                              setTimeToAdd({
                                ...timeToAdd,
                                [employee.id]: {
                                  ...(workTime || {}),
                                  startTime,
                                  endTime,
                                },
                              });
                            }}
                          />
                        </div>
                        <div
                          className="d-flex align-items-start flex-column py-2 col-12 px-0"
                          tag="div"
                        >
                          <Label className="d-flex">
                            <small>End Time</small>
                            <small className="ms-1 text-danger">*</small>
                          </Label>
                          <DebounceInput
                            debounceTimeout={900}
                            className="form-control-redesign px-0"
                            required={true}
                            step="60"
                            placeholder="End Time"
                            type="time"
                            value={endTime || ""}
                            onChange={(event) => {
                              const mEndTime = moment(
                                event.target.value,
                                "HH:mm"
                              );
                              const endTime = moment(selectedDate)
                                .set("hours", mEndTime.get("hours"))
                                .set("minutes", mEndTime.get("minutes"));
                              if (workTime.startTime) {
                                const mStartTime = moment(
                                  moment(workTime.startTime).format("HH:mm"),
                                  "HH:mm"
                                );
                                if (mEndTime.isBefore(mStartTime)) {
                                  endTime.add(1, "day");
                                }
                              }
                              setTimeToAdd({
                                ...timeToAdd,
                                [employee.id]: {
                                  ...(workTime || {}),
                                  endTime: endTime.toISOString(),
                                },
                              });
                            }}
                          />
                        </div>
                        {startTime && endTime ? (
                          <div
                            className="d-flex align-items-start flex-column py-2 col-12 px-0"
                            tag="div"
                          >
                            <Label>
                              <small>Hours</small>
                              <small className="ms-1 text-danger">*</small>
                            </Label>
                            <DebounceInput
                              debounceTimeout={900}
                              className="form-control-redesign"
                              placeholder="Hours..."
                              value={hours}
                              disabled={true}
                            />
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <div
                        className="d-flex align-items-start flex-column py-2 col-12 px-0"
                        tag="div"
                      >
                        <Label className="d-flex">
                          <small>Hours</small>
                          <small className="ms-1 text-danger">*</small>
                        </Label>
                        <DebounceInput
                          debounceTimeout={900}
                          className="form-control-redesign px-0"
                          required={true}
                          max={24}
                          min={0.1}
                          step={0.1}
                          placeholder="Hours"
                          type="number"
                          value={workTime || ""}
                          onChange={(evt) => onHoursChange(evt, employee.id)}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() =>
                      setEmployeeEnabled({
                        ...employeeEnabled,
                        [employee.id]: true,
                      })
                    }
                  >
                    Add Time
                  </Button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CrewMembers;
