import { data, sharedHelper, helpers } from "@crewos/shared";

import Schedule from "./pages/Schedule";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";

import "./assets/scss/schedules.scss";
import Settings from "./pages/settings/Settings";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const SCHEDULES = "SCHEDULES";

// Routes
const routes = [
  {
    name: "Calendar",
    icon: faCalendar,
    sidebar: true,
    path: "/schedules",
    package: "schedules",
    entries: [
      {
        icon: null,
        name: "Job Schedule",
        path: `job-schedule`,
        component: Schedule,
        sidebar: true,
      },
      {
        icon: null,
        name: "Supervisor Schedule",
        path: `supervisor-schedule`,
        component: Schedule,
        setting: "SUPER_SCHEDULE_ENABLED",
        sidebar: true,
      },
      {
        icon: null,
        name: "Crew Schedule",
        path: `crew-schedule`,
        component: Schedule,
        setting: "CREW_SCHEDULE_ENABLED",
        sidebar: true,
      },
    ],
  },
  {
    sidebar: true,
    name: "Schedules Settings",
    path: `schedules/settings`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "schedules",
    category: "Calendar",
    isSetting: true,
    component: Settings,
    order: 1,
  },
];

const Routes = createRoutes(routes);

const setScheduleSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "schedules");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: SCHEDULES, order: 6 },
    });
  }
};

export { Routes as ScheduleRoutes, setScheduleSidebarRoutes };
