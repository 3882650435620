import {
  faExclamationCircle,
  faInfoCircle,
  faTrashAlt,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
import React from "react";
import { components, data, sharedHelper } from "@crewos/shared";

const { TooltipItem } = components;

const { useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const CREWS_PACKAGE = "crews";

const WorkOrderCrewIndicators = ({ entry, onDeleteCrew }) => {
  const [authContext] = useAuth();

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  return (
    <div className="d-flex cursor-pointer">
      {entry.isLead && !directAssignationEnabled ? (
        <div>
          <TooltipItem
            id={`${entry.employee.id}-tooltip-1`}
            title={"Crew Lead"}
          >
            <FontAwesomeIcon
              icon={faShieldHalved}
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {!entry.employee.isActive ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-2`}
            title="Employee Inactive"
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {entry.createdAt ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-3`}
            title={`Added on ${moment(entry.createdAt).format("YYYY-MM-DD")}`}
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
      {directAssignationEnabled ? (
        <div className="ms-3">
          <TooltipItem
            id={`${entry.employee.id}-tooltip-3`}
            title="Delete assignment"
          >
            <FontAwesomeIcon
              onClick={onDeleteCrew}
              icon={faTrashAlt}
              className="text-danger"
              style={{ paddingBottom: "1px" }}
            />
          </TooltipItem>
        </div>
      ) : null}
    </div>
  );
};
export default WorkOrderCrewIndicators;
