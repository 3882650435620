import React from "react";

import placeholder from "../../assets/img/placeholder.png";
import { useAuth } from "../../providers/authProvider";

const SideBarUser = () => {
  const [authContext] = useAuth();

  return (
    <div className="mb-2 sidebar-user px-4 py-2  d-flex align-items-center justify-content-center">
      <div
        data-testid="user-image"
        className="rounded-circle img-thumbnail flex-shrink-0"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${placeholder})`,
          width: "48px",
          height: "48px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <span className="text-muted ms-4 fw-bold pt-1">
        {`${authContext.userData?.firstName} ${authContext.userData?.lastName}`}
      </span>
    </div>
  );
};

export default SideBarUser;
