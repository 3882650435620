import React, { useState, useCallback } from "react";

import { Row, FormGroup, Label } from "reactstrap";

import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "../assets/scss/reactDatePicker.scss";

import { sharedHelper } from "../helpers/sharedHelper";

const DatesSelector = ({ defaultStartDate, defaultEndDate, onSubmit }) => {
  const [startDate, setStartDate] = useState(defaultStartDate || null);
  const [endDate, setEndDate] = useState(defaultEndDate || null);

  const handleChangeDates = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
      const formattedStartDate = start
        ? sharedHelper.formatDate(start, "YYYY-MM-DD")
        : null;
      const formattedEndDate = end
        ? sharedHelper.formatDate(end, "YYYY-MM-DD")
        : null;
      onSubmit(formattedStartDate, formattedEndDate);
    },
    [setStartDate, setEndDate, onSubmit]
  );

  return (
    <div className="d-flex flex-column justify-content-center w-100">
      <Row className="d-flex cursor-pointer">
        <FormGroup className="col-6 d-flex flex-column">
          <Label>
            <span>Dates</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <DatePicker
            selected={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            startDate={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            endDate={endDate ? moment(endDate).startOf("day").toDate() : null}
            onChange={handleChangeDates}
            className="form-control-redesign d-flex align-items-center justify-content-between"
            selectsRange
            required
          />
        </FormGroup>
      </Row>
    </div>
  );
};

export default DatesSelector;
