import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  ListGroupItem,
} from "reactstrap";

import {
  useCreateWorkOrderCategory,
  useUpdateWorkOrderCategory,
} from "../api/WorkOrderCategory.hooks";

import { components, sharedHelper } from "@crewos/shared";

const { Loader, CustomCheckbox } = components;

const WorkOrderStatusCategoryModal = ({
  workOrderStatusCategory = {},
  onSubmit,
  onClose,
}) => {
  const [name, setName] = useState(workOrderStatusCategory.name || "");
  const [order, setOrder] = useState(
    workOrderStatusCategory.order ? String(workOrderStatusCategory.order) : "1"
  );
  const [isWoReadOnly, setIsWoReadOnly] = useState(
    workOrderStatusCategory.isWoReadOnly || false
  );
  const [isAppList, setIsAppList] = useState(
    workOrderStatusCategory.isAppList || false
  );
  const [defaultSelected, setDefaultSelected] = useState(
    workOrderStatusCategory.defaultSelected || false
  );

  const {
    isLoading: isLoadingCreateWorkOrderStatusCategory,
    mutate: createWorkOrderStatusCategory,
    data: createWorkOrderStatusCategoryData,
  } = useCreateWorkOrderCategory();

  useEffect(() => {
    if (createWorkOrderStatusCategoryData) {
      sharedHelper.successToast(`Work order status category created`);
      onSubmit();
    }
  }, [createWorkOrderStatusCategoryData, onSubmit]);

  const {
    isLoading: isLoadingUpdateWorkOrderStatusCategory,
    update: updateWorkOrderStatusCategory,
    data: updateWorkOrderStatusCategoryData,
  } = useUpdateWorkOrderCategory();

  useEffect(() => {
    if (updateWorkOrderStatusCategoryData) {
      sharedHelper.successToast(`Work order status category saved`);
      onSubmit();
    }
  }, [updateWorkOrderStatusCategoryData, onSubmit]);

  const doSubmit = async (event) => {
    event.preventDefault();
    if (workOrderStatusCategory.id) {
      await updateWorkOrderStatusCategory({
        id: workOrderStatusCategory.id,
        name,
        order,
        isWoReadOnly,
        isAppList,
      });
    } else {
      await createWorkOrderStatusCategory({
        name,
        order,
        isWoReadOnly,
        isAppList,
      });
    }
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {workOrderStatusCategory.id ? "Edit" : "Add"} Work Order Status Category
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateWorkOrderStatusCategory ||
          isLoadingUpdateWorkOrderStatusCategory ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  <span>Order</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  max="999"
                  min="1"
                  type="number"
                  placeholder="Enter the order"
                  name="order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup className="mt-4">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setIsWoReadOnly(!isWoReadOnly)}
                >
                  <div className="flex-shrink-0">WO Read Only</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={isWoReadOnly} />
                  </div>
                </ListGroupItem>
              </FormGroup>
              <FormGroup className="mt-4">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setIsAppList(!isAppList)}
                >
                  <div className="flex-shrink-0">Listed in APP</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={isAppList} />
                  </div>
                </ListGroupItem>
              </FormGroup>
              <FormGroup className="mt-4">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setDefaultSelected(!defaultSelected)}
                >
                  <div className="flex-shrink-0">Default Selected</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={defaultSelected} />
                  </div>
                </ListGroupItem>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkOrderStatusCategory ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderStatusCategoryModal;
