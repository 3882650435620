import React from "react";
import { Row, Card, CardBody, Col } from "reactstrap";

import CrewWorkDay from "./CrewWorkDay";

import { useCrewWorkDays } from "@crewos/crews";

import { data, components } from "@crewos/shared";

const { WorkOrderDateSelector, Loader } = components;

const { useWorkOrderDetails } = data;

const STATUS_INVOICED = "Invoiced";

const CrewsEquipments = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 mb-3 px-0">
          <CardBody className="border-top-left-radius-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0">Equipment</h2>
            </div>
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {workOrderDetails.loading ? (
          <Card className="w-100">
            <CardBody className="py-3">
              <Loader size="sm" />
            </CardBody>
          </Card>
        ) : !crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] ? (
          <Card className="w-100 px-0">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate].map(
            (crewWorkDay) => (
              <CrewWorkDay
                key={crewWorkDay.id}
                crewWorkDay={crewWorkDay}
                isReadOnly={
                  workOrderDetails.workOrder.workOrderStatus.name ===
                  STATUS_INVOICED
                }
              />
            )
          )
        )}
      </Row>
    </Col>
  );
};

export default CrewsEquipments;
