import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Label,
  Form,
} from "reactstrap";

import {
  useCreateWorkOrderStatus,
  useUpdateWorkOrderStatus,
} from "../api/WorkOrderStatus.hooks";

import { components, sharedHelper } from "@crewos/shared";
import { useGetAllWorkOrderCategory } from "../api/WorkOrderCategory.hooks";

import Select from "react-select";

import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const { Loader } = components;

const WorkOrderStatusModal = ({ workOrderStatus = {}, onSubmit, onClose }) => {
  const [workOrderCategory, setWorkOrderCategory] = useState(
    workOrderStatus.workOrderCategory
      ? {
        label: workOrderStatus.workOrderCategory.name,
        value: workOrderStatus.workOrderCategory.id,
      }
      : null
  );

  const [workOrderCategories, setWorkOrderCategories] = useState();

  const [name, setName] = useState(workOrderStatus.name || "");
  const [order, setOrder] = useState(workOrderStatus.order || 1);

  const {
    isLoading: isLoadingCreateWorkOrderStatus,
    mutate: createWorkOrderStatus,
    data: createWorkOrderStatusData,
  } = useCreateWorkOrderStatus();

  const {
    data: workOrderCategoryData,
    isLoading: isLoadingCategory,
    get: getWorkOrderCategory,
  } = useGetAllWorkOrderCategory();

  useEffect(() => {
    getWorkOrderCategory();
  }, [getWorkOrderCategory]);

  useEffect(() => {
    if (workOrderCategoryData) {
      setWorkOrderCategories(workOrderCategoryData);
    }
  }, [workOrderCategoryData]);

  useEffect(() => {
    if (createWorkOrderStatusData) {
      sharedHelper.successToast(`Work order status created`);
      onSubmit();
    }
  }, [createWorkOrderStatusData, onSubmit]);

  const {
    isLoading: isLoadingUpdateWorkOrderStatus,
    update: updateWorkOrderStatus,
    data: updateWorkOrderStatusData,
  } = useUpdateWorkOrderStatus();

  useEffect(() => {
    if (updateWorkOrderStatusData) {
      sharedHelper.successToast(`Work order status saved`);
      onSubmit();
    }
  }, [updateWorkOrderStatusData, onSubmit]);

  const doSubmit = async (event) => {
    event.preventDefault();
    if (!workOrderCategory) {
      return sharedHelper.warningToast(`Please select a work order category.`);
    }
    if (workOrderStatus.id) {
      await updateWorkOrderStatus({
        id: workOrderStatus.id,
        name,
        workOrderCategoryId: workOrderCategory.value,
        order,
      });
    } else {
      await createWorkOrderStatus({
        name,
        workOrderCategoryId: workOrderCategory.value,
        order,
      });
    }
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {workOrderStatus.id ? "Edit" : "Add"} Work Order Status
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateWorkOrderStatus ||
            isLoadingUpdateWorkOrderStatus ||
            isLoadingCategory ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  <span>Order</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  max="999"
                  min="1"
                  type="number"
                  placeholder="Enter the order"
                  name="order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  required
                />
              </FormGroup>
              {workOrderCategories ? (
                <FormGroup>
                  <FormGroup>
                    <Label>
                      <span>Category</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      styles={style}
                      placeholder="Select a category.."
                      classNamePrefix="react-select"
                      className="text-start bg-white px-0 react-select"
                      options={workOrderCategories?.map((category) => ({
                        value: category.id,
                        label: category.name,
                      }))}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      value={workOrderCategory}
                      onChange={(selected) => {
                        setWorkOrderCategory(selected);
                      }}
                    />
                  </FormGroup>
                </FormGroup>
              ) : (
                <div className="min-width-50">
                  <Loader size="sm" />
                </div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkOrderStatus ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderStatusModal;
