import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons";

import {
  CreateTemplates,
  EditTemplates,
  ListTemplates,
  ViewTemplate,
} from "./pages";

import InspectionWrapper from "./components/InspectionWrapper";

import { helpers } from "@crewos/shared";

const { createRoutes } = helpers;

const routes = [
  {
    name: "Inspections",
    icon: faFileCircleCheck,
    sidebar: true,
    path: "/inspections",
    package: "inspections",
    component: InspectionWrapper,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    entries: [
      {
        icon: null,
        path: "templates/create",
        name: "New Template",
        component: CreateTemplates,
        sidebar: true,
      },
      {
        icon: null,
        path: "templates/edit/:id",
        name: "Edit Template",
        component: EditTemplates,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates/view/:id",
        name: "View Template",
        component: ViewTemplate,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates",
        name: "Templates",
        component: ListTemplates,
        sidebar: true,
      },
    ],
  },
];

const Routes = createRoutes(routes);

export { Routes, routes };
