import React, { useCallback } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "reactstrap";
import InputEditable from "./InputEditable";
import { faTrash, faCopy } from "@fortawesome/free-solid-svg-icons";
import Questions from "./Questions";
import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const GroupItem = ({ group, isReadOnly }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const { updateGroup, getQuestions, removeGroup, copyGroup, getErrors } =
    useInspectionTemplate();

  const questions = getQuestions(group.id);

  const handleUpdateName = useCallback(
    (group, $event) => {
      const value = $event.target.value;
      updateGroup({ ...group, name: value });
    },
    [updateGroup]
  );

  const handleCopyGroup = useCallback(
    (event) => {
      event.stopPropagation();
      copyGroup(group);
    },
    [copyGroup, group]
  );

  const handleRemoveGroup = useCallback(
    (event) => {
      event.stopPropagation();
      removeGroup(group);
    },
    [removeGroup, group]
  );

  return (
    <div className="w-100 border border-1 border-opacity-25 solid rounded-4 py-3 px-4 bg-white sortable-item">
      <Row
        className="d-flex justify-content-between align-items-center gap-4 mb-3"
        onClick={() => setIsExpanded((status) => !status)}
      >
        <Col>
          <InputEditable
            value={group.name}
            disabled={isReadOnly}
            onChange={($evt) => handleUpdateName(group, $evt)}
            onClick={($evt) => $evt.stopPropagation()}
            placeholder="Group Name"
            name="group-name"
            size="medium"
            aria-label="Group Name"
            errors={getErrors(group.id)}
          />
        </Col>
        <Col className="d-flex justify-content-end align-items-center gap-3">
          <div className="small text-muted">
            {questions?.length || 0} questions
          </div>
          {!isReadOnly && (
            <>
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button border-0"
                color="white"
                onClick={handleCopyGroup}
                aria-label="Copy Group"
              >
                <FontAwesomeIcon icon={faCopy} size="xl" />
              </Button>
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                color="white"
                onClick={handleRemoveGroup}
                aria-label="Remove Group"
              >
                <FontAwesomeIcon icon={faTrash} size="xl" />
              </Button>
            </>
          )}
          <Button className="btn btn-link d-flex align-items-center gap-2 expand-button text-decoration-none">
            <small className="text-primary fw-bold">
              View {isExpanded ? "less" : "more"}
            </small>
            <FontAwesomeIcon
              className={`ms-2 ${isExpanded ? "" : "rotate"}`}
              icon={faChevronDown}
            />
          </Button>
        </Col>
      </Row>
      {isExpanded && (
        <Row className="group-item-content__content">
          <Col>
            <Questions
              questions={questions}
              groupId={group.id}
              isReadOnly={isReadOnly}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default GroupItem;
