import React, { useState, useCallback } from "react";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

import { sharedHelper } from "../helpers/sharedHelper";

const CustomPeriodSelector = ({
  defaultStartDate,
  defaultEndDate,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const [startDate, setStartDate] = useState(
    moment(defaultStartDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD")
  );

  const handleClose = useCallback(() => setIsOpen(), [setIsOpen]);

  const handleChangeDateRange = useCallback(
    (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    },
    [setStartDate, setEndDate]
  );

  return (
    <>
      <div
        className="me-3 d-flex align-items-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <Button
          className="d-none border bg-graylight text-primary"
          onClick={(evt) => {
            evt.stopPropagation();
            const newStartDate = moment(defaultStartDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD");
            const newEndDate = moment(defaultEndDate)
              .subtract(1, "day")
              .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onSubmit(newStartDate, newEndDate);
          }}
        >
          <FontAwesomeIcon className="text-primary" icon={faChevronLeft} />
        </Button>
        <span
          className="mx-1 p-2 text-center flex-shrink-0 text-link"
          //style={{ minWidth: 150 }}
        >
          {sharedHelper.formatDateRange(defaultStartDate, defaultEndDate)}
        </span>
        <Button
          className="d-none border bg-graylight text-primary"
          onClick={(evt) => {
            evt.stopPropagation();
            const newStartDate = moment(defaultStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
            const newEndDate = moment(defaultEndDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            onSubmit(newStartDate, newEndDate);
          }}
        >
          <FontAwesomeIcon className="text-primary" icon={faChevronRight} />
        </Button>
      </div>
      <Modal isOpen={isOpen} onClosed={handleClose} size="md">
        <ModalHeader
          toggle={handleClose}
          className="d-flex justify-content-between"
        >
          Date Range Selector
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <DatePicker
            selected={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            startDate={
              startDate ? moment(startDate).startOf("day").toDate() : null
            }
            endDate={endDate ? moment(endDate).startOf("day").toDate() : null}
            onChange={handleChangeDateRange}
            selectsRange
            inline
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={() => setIsOpen()}>
            Close
          </Button>{" "}
          <div className="min-width-50">
            <Button
              disabled={!startDate || !endDate}
              color={"primary"}
              onClick={() => {
                setIsOpen(false);
                onSubmit(
                  moment(startDate).format("YYYY-MM-DD"),
                  moment(endDate).format("YYYY-MM-DD")
                );
              }}
            >
              Save
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CustomPeriodSelector;
