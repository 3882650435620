import { data, sharedHelper, helpers } from "@crewos/shared";

import Equipments from "./pages/Equipments";
import CrewWorkDay from "./components/CrewWorkDay";
import CrewsEquipments from "./components/CrewsEquipments";

import { faCogs } from "@fortawesome/free-solid-svg-icons";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const EQUIPMENTS = "EQUIPMENTS";

// Routes
const routes = [
  {
    path: "/equipment",
    name: "Equipment",
    package: "equipments",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    icon: faCogs,
    sidebar: true,
    component: Equipments,
  },
];

const Routes = createRoutes(routes);

const setEquipmentSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "equipments");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: EQUIPMENTS, order: 6 },
    });
  }
};

export {
  CrewWorkDay,
  CrewsEquipments,
  Routes as EquipmentRoutes,
  setEquipmentSidebarRoutes,
};
