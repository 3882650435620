import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import {
  useCreateEquipmentOption,
  useUpdateEquipmentOption,
} from "../api/EquipmentOptions.hooks";

import { components, sharedHelper } from "@crewos/shared";

const { Loader } = components;

const EquipmentModal = ({
  equipmentOption = { isActive: true },
  onClose,
  onSubmit,
}) => {
  const [equipmentOptionData, setEquipmentOptionData] =
    useState(equipmentOption);

  const {
    isLoading: isLoadingUpdateEquipmentOption,
    update: updateEquipmentOption,
    data: updateEquipmentOptionData,
  } = useUpdateEquipmentOption();

  const {
    isLoading: isLoadingCreateEquipmentOption,
    mutate: createEquipmentOption,
    data: createEquipmentOptionData,
  } = useCreateEquipmentOption();

  useEffect(() => {
    if (updateEquipmentOptionData) {
      sharedHelper.successToast(`Equipment saved`);
      onSubmit();
    }
  }, [updateEquipmentOptionData, onSubmit]);

  useEffect(() => {
    if (createEquipmentOptionData) {
      sharedHelper.successToast(`Equipment created`);
      onSubmit();
    }
  }, [createEquipmentOptionData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (equipmentOptionData.id) {
      await updateEquipmentOption(equipmentOptionData);
    } else {
      await createEquipmentOption(equipmentOptionData);
    }
  };

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {equipmentOptionData.id ? "Edit" : "Create"} Equipment
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingCreateEquipmentOption ||
              isLoadingUpdateEquipmentOption ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    <span>Name</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    maxLength="50"
                    type="text"
                    name="name"
                    placeholder="Enter the name.."
                    value={equipmentOptionData.name || ""}
                    onChange={(e) =>
                      setEquipmentOptionData({
                        ...equipmentOptionData,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                {equipmentOption.id ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Status</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      type="select"
                      name="statusSelect"
                      id="statusSelect"
                      onChange={(e) => {
                        setEquipmentOptionData({
                          ...equipmentOptionData,
                          isActive: e.target.value === "true",
                        });
                      }}
                      value={equipmentOptionData.isActive ? "true" : "false"}
                      required
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </FormGroup>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EquipmentModal;
