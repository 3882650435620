import React, {useCallback} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useDeleteInspection} from "../api/Inspections.hooks";

const DeleteInspectionModal = ({ inspectionId, onClose, isOpen, onDelete }) => {
  const { mutate: deleteInspection, isLoading: isLoadingDeleteInspection } = useDeleteInspection();

  const handleDelete = useCallback(async () => {
    await deleteInspection({ id: inspectionId });
    onDelete();
  }, [inspectionId, deleteInspection, onDelete]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={onClose}>
        Delete Inspection
      </ModalHeader>
      <ModalBody>
        <p>
          Are you sure you want to delete this inspection?
        </p>
      </ModalBody>
      <ModalFooter className="justify-content-between flex-row flex-nowrap">
        <Button color="secondary" onClick={onClose} className="w-100" disabled={isLoadingDeleteInspection}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleDelete} className="w-100" disabled={isLoadingDeleteInspection}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteInspectionModal;
