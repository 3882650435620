import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/work-time`;

export const useGetWorkTimes = () => {
  return useApiGet(endpoint);
};

export const useCreateWorkTime = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateWorkTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (workTime) => {
      await mutate(workTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useBulkUpdateWorkTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/bulk`
  );

  const bulkUpdate = useCallback(
    async (workTimes) => {
      await mutate(workTimes);
    },
    [mutate]
  );

  return { ...rest, bulkUpdate };
};

export const useDeleteWorkTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteWorkTime = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteWorkTime };
};

export const useBulkDeleteWorkTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/bulk`
  );

  const bulkDeleteWorkTimes = useCallback(
    async (workTimes) => {
      await mutate(workTimes);
    },
    [mutate]
  );

  return { ...rest, bulkDeleteWorkTimes };
};
