import React, { useState } from "react";

import { Row, Card, CardBody, Button, Col } from "reactstrap";

import { data, sharedHelper, components } from "@crewos/shared";

import CrewWorkDayTimes from "./CrewWorkDayTimes";

import { AddWorkTimeModal } from "@crewos/worktimes";

import { AddTravelTimeModal } from "@crewos/traveltimes";

import {
  useCrewWorkDays,
  ACTIONS as CREW_WORK_DAY_ACTIONS,
} from "../providers/crewWorkDaysProvider";

const { WorkOrderDateSelector, Loader } = components;

const { useAuth, useWorkOrderDetails } = data;

const WorkOrderCrewsTimes = () => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [addWorkTimeModal, setAddWorkTimeModal] = useState(false);
  const [addTravelTimeModal, setAddTravelTimeModal] = useState(false);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 mb-3 px-0">
          <CardBody className="border-top-left-radius-0">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0">Time</h2>
              <div className="d-flex">
                {sharedHelper.isPackageEnabled(
                  authContext.userData?.packages,
                  "worktimes"
                ) ? (
                  <Button
                    color="primary"
                    className="ms-2"
                    onClick={() => setAddWorkTimeModal(true)}
                  >
                    Add Time
                  </Button>
                ) : null}
                {sharedHelper.isPackageEnabled(
                  authContext.userData?.packages,
                  "traveltimes"
                ) ? (
                  <Button
                    color="primary"
                    className="ms-2"
                    onClick={() => setAddTravelTimeModal(true)}
                  >
                    Add Travel
                  </Button>
                ) : null}
              </div>
            </div>
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {workOrderDetails.loading ? (
          <Card className="w-100">
            <CardBody className="py-3">
              <Loader size="sm" />
            </CardBody>
          </Card>
        ) : !crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] ? (
          <Card className="w-100 px-0">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate].map(
            (crewWorkDay) => (
              <CrewWorkDayTimes
                key={crewWorkDay.id}
                crewWorkDay={crewWorkDay}
              />
            )
          )
        )}
      </Row>
      {addWorkTimeModal ? (
        <AddWorkTimeModal
          onSubmit={() => {
            setAddWorkTimeModal(false);
            sharedHelper.successToast(`Time added`);
            setCrewWorkDaysContext({
              action: CREW_WORK_DAY_ACTIONS.REFRESH,
            });
          }}
          onClose={() => setAddWorkTimeModal(false)}
        />
      ) : addTravelTimeModal ? (
        <AddTravelTimeModal
          onSubmit={() => {
            setAddTravelTimeModal(false);
            sharedHelper.successToast(`Time added`);
            setCrewWorkDaysContext({
              action: CREW_WORK_DAY_ACTIONS.REFRESH,
            });
          }}
          onClose={() => setAddTravelTimeModal(false)}
        />
      ) : null}
    </Col>
  );
};

export default WorkOrderCrewsTimes;
