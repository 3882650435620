import React, { useCallback, useMemo, useState } from "react";
import { Button } from "reactstrap";
import { components, data, sharedHelper, getStatusColor } from "@crewos/shared";

import moment from "moment";
import DeleteInspectionModal from "./DeleteInspectionModal";
import InspectionDetailModal from "./InspectionDetailModal";
import { useGetInspection } from "../api/Inspections.hooks";
import {
  INSPECTION_DETAIL_ACTIONS,
  InspectionDetailProvider,
  useInspectionDetail,
} from "../providers/inspectionDetailProvider";

const { useAuth } = data;
const { StatusFilter, AdvanceTable, ImagesViewerModal } = components;

const filters = [
  { name: "In Progress", id: "IN_PROGRESS", index: 0 },
  { name: "Completed", id: "COMPLETED", index: 1 },
];

const getStatusColorByName = (name) => {
  const filterItem = filters.find((filter) => filter.id === name);
  if (!filterItem) {
    return {
      color: null,
      item: {},
    };
  }
  return {
    color: getStatusColor(filterItem.index, false),
    item: filterItem,
  };
};

const columns = (onViewInspection, onDelete) => [
  {
    accessor: "createdAt",
    header: "Created Date",
    headerProps: { className: "text-truncate", style: { maxWidth: 300 } },
    Cell: (rowData) => {
      const { createdAt } = rowData.row;
      return moment(createdAt).format("MM/DD/YYYY");
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: {
      className: "text-truncate text-start",
      style: { maxWidth: 300 },
    },
    cellProps: {
      className: "text-center",
    },
    Cell: (rowData) => {
      const { status } = rowData.row;
      const statusWithColor = getStatusColorByName(status);
      return (
        <div className="text-start" data-value={statusWithColor.item.name}>
          <span style={statusWithColor.color} className="p-2">
            {statusWithColor.item.name}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    disableSortBy: true,
    headerProps: { style: { minWidth: 300 } },
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => {
      const { id } = rowData.row;
      return (
        <div className="d-flex gap-2 justify-content-end">
          <Button color="primary" onClick={() => onViewInspection(id)}>
            View Inspection
          </Button>
          <Button color="danger" onClick={() => onDelete(id)}>
            Delete
          </Button>
        </div>
      );
    },
  },
];

const Inspections = ({ inspections, onInspectionDelete }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedInspectionId, setSelectedInspectionId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    data: inspectionData,
    isLoading: inspectionLoading,
    get: getInspection,
  } = useGetInspection();
  const { state, dispatch } = useInspectionDetail();

  const handleSelectFilter = useCallback((status) => {
    setSelectedFilter(status);
  }, []);

  const handleCloseViewInspection = useCallback(() => {
    dispatch({ type: INSPECTION_DETAIL_ACTIONS.CLOSE_INSPECTION_MODAL });
    // setIsInspectionModalOpen(false);
  }, [dispatch]);

  const handleViewInspection = useCallback(
    async (id) => {
      await getInspection({ id });
      dispatch({ type: INSPECTION_DETAIL_ACTIONS.OPEN_INSPECTION_MODAL });
      // setIsInspectionModalOpen(true);
    },
    [getInspection, dispatch]
  );

  const handleClosePreviewModal = useCallback(() => {
    dispatch({ type: INSPECTION_DETAIL_ACTIONS.OPEN_INSPECTION_MODAL });
  }, [dispatch]);

  const handleDelete = useCallback((id) => {
    setSelectedInspectionId(id);
    setIsDeleteModalOpen(true);
  }, []);

  const handleOnDelete = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedInspectionId(null);
    onInspectionDelete();
  }, [onInspectionDelete]);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedInspectionId(null);
  }, []);

  const filteredInspections = useMemo(() => {
    return inspections.filter((inspection) => {
      if (!selectedFilter) {
        return true;
      }
      return inspection.status === selectedFilter.id;
    });
  }, [inspections, selectedFilter]);

  const isOpenInspectionDetail = useMemo(() => {
    return inspectionData && !inspectionLoading && state.isOpenInspectionModal;
  }, [inspectionData, inspectionLoading, state]);

  return (
    <div className="overflow-x-auto">
      <div className="d-flex align-items-center me-3 position-relative mb-3 small">
        <StatusFilter
          statuses={filters}
          selectedStatus={selectedFilter}
          setSelectedStatus={handleSelectFilter}
        />
      </div>
      <AdvanceTable
        headerClassName="text-muted small"
        columns={columns(handleViewInspection, handleDelete)}
        data={filteredInspections || []}
        pageSize={15}
      />
      <DeleteInspectionModal
        isOpen={isDeleteModalOpen}
        inspectionId={selectedInspectionId}
        onClose={handleCloseDeleteModal}
        onDelete={handleOnDelete}
      />
      <InspectionDetailModal
        isOpen={isOpenInspectionDetail}
        inspection={inspectionData}
        onClose={handleCloseViewInspection}
        status={getStatusColorByName(inspectionData?.status)}
      />
      {state.isOpenPreviewModal && (
        <ImagesViewerModal
          title="Attachments Images"
          items={state.attachments}
          onClose={handleClosePreviewModal}
          slide={false}
        />
      )}
    </div>
  );
};

const InspectionsWrapper = (props) => {
  const [authContext] = useAuth();
  const isPackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "inspections"
  );
  if (!isPackageEnabled) {
    return null;
  }

  return (
    <InspectionDetailProvider>
      <Inspections {...props} />
    </InspectionDetailProvider>
  );
};

export default InspectionsWrapper;
