import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useUpdateNonJobTime,
  useDeleteNonJobTime,
} from "../api/NonJobTimes.hooks";

const { Loader } = components;

const EditNonJobTimeModal = ({ nonJobTime, employee, onClose, onSubmit }) => {
  const [localEntry, setLocalEntry] = useState({ ...nonJobTime });

  const {
    isLoading: isLoadingUpdateNonJobTime,
    update: updateNonJobTime,
    data: updateNonJobTimeData,
  } = useUpdateNonJobTime();

  const { data: deleteNonJobTimeData, deleteNonJobTime } =
    useDeleteNonJobTime();

  useEffect(() => {
    if (updateNonJobTimeData || deleteNonJobTimeData) {
      sharedHelper.successToast(
        `Non job times ${updateNonJobTimeData ? "updated" : "deleted"}`
      );
      onSubmit(localEntry);
    }
  }, [updateNonJobTimeData, deleteNonJobTimeData, onSubmit, localEntry]);

  const onNonJobTimeChanged = (data, field, nonJobTime) => {
    const editedNonJobTime = {
      ...nonJobTime,
      [field]: data,
      touched: true,
      deleted: false,
    };
    setLocalEntry(editedNonJobTime);
  };

  const onNonJobTimeDeleted = (nonJobTime) => {
    const deletedNonJobTime = {
      ...nonJobTime,
      deleted: true,
      touched: false,
    };
    setLocalEntry(deletedNonJobTime);
  };

  const doSubmit = async (event) => {
    event.preventDefault();

    if (!localEntry.touched && !localEntry.deleted) {
      sharedHelper.successToast(`No changes applied`);
      return onClose();
    }

    if (localEntry.touched) {
      updateNonJobTime({ ...localEntry });
    } else if (localEntry.deleted) {
      deleteNonJobTime({ ...localEntry });
    }
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <span>Edit Non Job Time</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="mt-n3">
          {!localEntry.deleted ? (
            <div className="d-flex flex-column align-items-center justify-content-between py-3">
              <div className="d-flex align-items-center justify-content-between col-12 px-0 mb-1">
                <div className="d-flex align-items-center justify-content-start">
                  <small className="text-muted me-2">Employee:</small>
                  <small>{`${employee.firstName} ${employee.lastName}`}</small>
                </div>
                <Button
                  className="float-end d-flex align-items-center"
                  color="none"
                  size="sm"
                  onClick={() => onNonJobTimeDeleted(nonJobTime)}
                  data-testid="delete-button"
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-danger"
                    size="lg"
                  />
                </Button>
              </div>
              <div
                className="d-flex align-items-start flex-column py-2 col-12 px-0"
                tag="div"
              >
                <Label>
                  <small>Hours</small>
                  <small className="ms-1 text-danger">*</small>
                </Label>
                <input
                  className="form-control-redesign"
                  type="number"
                  placeholder="Enter the mileage"
                  required={true}
                  max={24}
                  min={0}
                  step={0.1}
                  value={localEntry.hours}
                  onChange={(event) =>
                    onNonJobTimeChanged(
                      event.currentTarget.value,
                      "hours",
                      localEntry
                    )
                  }
                />
              </div>
            </div>
          ) : (
            <div className="col-12 px-0 text-muted text-center mt-3">
              No time entries to show
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Discard
          </Button>
          {isLoadingUpdateNonJobTime || isLoadingUpdateNonJobTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button type="submit" color="primary">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditNonJobTimeModal;
