import React from "react";

import { CrewWorkDayWorkTimes } from "@crewos/worktimes";
import { CrewWorkDayTravelTimes } from "@crewos/traveltimes";
import CrewWorkDayCrew from "./CrewWorkDayCrew";
import { useCrews } from "../providers/crewsProvider";

const CrewWorkDayTimes = ({ crewWorkDay }) => {
  const [crewsContext] = useCrews();

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );

  return crew ? (
    <CrewWorkDayCrew crew={crew}>
      <CrewWorkDayWorkTimes crewWorkDay={crewWorkDay} crew={crew} />
      <CrewWorkDayTravelTimes crewWorkDay={crewWorkDay} crew={crew} />
    </CrewWorkDayCrew>
  ) : null;
};

export default CrewWorkDayTimes;
