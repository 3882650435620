import Moment from "moment";

import { sharedHelper } from "@crewos/shared";

const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const MAX_CREW_SIZE = "MAX_CREW_SIZE";

export const crewHelper = {
  isEmployeeCrewActiveOnDate: (
    employeeCrewPastDatesEnabled,
    employeeCrew,
    date = moment()
  ) => {
    const isCreatedBefore =
      employeeCrewPastDatesEnabled ||
      moment(employeeCrew.createdAt).isSameOrBefore(moment(date), "date");
    const isDisabledAfter =
      !employeeCrew.disabledAt ||
      moment(employeeCrew.disabledAt).isAfter(moment(date), "date");
    const isEmployeeCrewActiveOnDate = isCreatedBefore && isDisabledAfter;
    return isEmployeeCrewActiveOnDate;
  },
  getCrewLead: (employeeCrewPastDatesEnabled, crew, date) =>
    crew.employeeCrews
      .filter((ec) =>
        crewHelper.isEmployeeCrewActiveOnDate(
          employeeCrewPastDatesEnabled,
          ec,
          date
        )
      )
      .find((ec) => ec.isLead),
  getCrewSizeSetting: (packages) => {
    const crewPackage = packages.find((p) => p.path === "crews");
    const crewSizeSetting = crewPackage?.settings.find(
      (s) => s.name === MAX_CREW_SIZE
    );
    return crewSizeSetting;
  },
  getEmployeesByRole: (crew) =>
    crew.employeeCrews
      .filter((ec) => !ec.disabledAt)
      .sort(sharedHelper.sortEmployeeCrew)
      .reduce((p, c) => {
        p[c.role.name] = [
          ...(p[c.role.name] || []),
          {
            employee: c.employee,
            createdAt: c.createdAt,
            roleId: c.role.id,
            isLead: c.isLead,
          },
        ];
        return p;
      }, {}),
};
