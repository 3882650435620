import React, { useState, useEffect } from "react";

import { Button, Card, CardBody, Row } from "reactstrap";

import { components } from "@crewos/shared";

import AddEquipmentModal from "./AddEquipmentModal";

import { useGetEquipments } from "../api/Equipments.hooks";

import Equipment from "./Equipment";

const { Loader } = components;

const CrewWorkDayEquipments = ({ crewWorkDay, isReadOnly }) => {
  const [refresh, setRefresh] = useState();
  const [equipments, setEquipments] = useState([]);

  const [addEquipmentModal, setAddEquipmentModal] = useState();

  const {
    data: getEquipmentsData,
    isLoading: isLoadingGetEquipments,
    get: getEquipments,
  } = useGetEquipments();

  useEffect(() => {
    getEquipments({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getEquipments, refresh, crewWorkDay]);

  useEffect(() => {
    if (getEquipmentsData) {
      setEquipments(getEquipmentsData);
    }
  }, [getEquipmentsData, setEquipments]);

  return (
    <>
      {isLoadingGetEquipments ? (
        <Loader size="sm" />
      ) : (
        <Card className="w-100 mb-3 px-0">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h3>Equipment</h3>
              {!isReadOnly ? (
                <div className="col-2">
                  <div className="d-flex justify-content-end">
                    <Button
                      color="primary"
                      className="d-flex align-items-center ms-2"
                      onClick={() => setAddEquipmentModal(true)}
                    >
                      Assign
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            <Row className="mt-3 d-flex justify-content-start align-items-start">
              {equipments.length ? (
                equipments.map((equipment) => (
                  <div className="col-3" key={equipment.id}>
                    <Equipment
                      equipment={equipment}
                      readOnly={false}
                      onRefresh={() => setRefresh((prev) => !prev)}
                    />
                  </div>
                ))
              ) : (
                <div className="my-1 p-2 small text-muted text-center">
                  No equipments
                </div>
              )}
            </Row>
          </CardBody>
        </Card>
      )}
      {addEquipmentModal ? (
        <AddEquipmentModal
          equipments={equipments}
          crewWorkDayId={crewWorkDay.id}
          onClose={() => setAddEquipmentModal()}
          onSubmit={() => {
            setAddEquipmentModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </>
  );
};

export default CrewWorkDayEquipments;
