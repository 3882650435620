import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";

import { useGetNotes, useCreateNote, useUpdateNote } from "../api/Notes.hooks";

import { components, data } from "@crewos/shared";

const { Loader } = components;
const { useWorkOrderDetails } = data;

const NOTE_TYPE_CUSTOMER = "Customer";
const NOTE_TYPE_DAILY_WORK = "Daily Work Description";
const NOTE_TYPE_OFFICE = "Office";

const types = [
  {
    label: NOTE_TYPE_CUSTOMER,
    value: NOTE_TYPE_CUSTOMER,
  },
  {
    label: NOTE_TYPE_DAILY_WORK,
    value: NOTE_TYPE_DAILY_WORK,
  },
  {
    label: NOTE_TYPE_OFFICE,
    value: NOTE_TYPE_OFFICE,
  },
];

const NoteModal = ({ noteId, crewWorkDayId, onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [note, setNote] = useState({
    type: "",
    content: "",
  });

  const {
    data: notesData,
    isLoading: isLoadingNotes,
    get: getNotes,
  } = useGetNotes();

  const {
    isLoading: isLoadingUpdateNote,
    update: updateNote,
    data: updateNoteData,
  } = useUpdateNote();

  const {
    isLoading: isLoadingCreateNote,
    mutate: createNote,
    data: createNoteData,
  } = useCreateNote();

  useEffect(() => {
    if (noteId) {
      getNotes({ id: noteId });
    }
  }, [getNotes, noteId]);

  useEffect(() => {
    if (notesData) {
      setNote(notesData);
    }
  }, [notesData]);

  useEffect(() => {
    if (updateNoteData) {
      onSubmit();
    }
  }, [updateNoteData, onSubmit]);

  useEffect(() => {
    if (createNoteData) {
      onSubmit();
    }
  }, [createNoteData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (note.id) {
      const { id, content, type } = note;
      await updateNote({
        id,
        content,
        type,
      });
    } else {
      await createNote({
        ...note,
        crewWorkDayId,
        workOrderId: workOrderDetails.workOrder.id,
      });
    }
  };

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {noteId ? "Edit" : "Create"} Note
        </ModalHeader>
        <ModalBody>
          <Row>
            {(noteId && isLoadingNotes) ||
              isLoadingCreateNote ||
              isLoadingUpdateNote ? (
              <Loader size="sm" />
            ) : (
              <Col>
                <FormGroup>
                  <Label htmlFor="typeSelect">
                    <span>Type</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <select
                    className="form-control-redesign"
                    type="select"
                    name="typeSelect"
                    id="typeSelect"
                    onChange={(e) =>
                      setNote({
                        ...note,
                        type: e.target.value,
                      })
                    }
                    value={note.type}
                    required
                  >
                    <option value={""}>Select a Type</option>
                    {types.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="content">
                    <span>Content</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="textarea"
                    name="content"
                    id="content"
                    placeholder="Enter the content.."
                    value={note.content}
                    onChange={(e) =>
                      setNote({
                        ...note,
                        content: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NoteModal;
