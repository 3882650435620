import React, { useEffect, useState } from "react";

import { Card, CardHeader, CardBody, Container, Button } from "reactstrap";

import { faSync, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "react-router";

import {
  useDeleteSmartReport,
  useGenerateSmartReport,
  useGetSmartReports,
  useUpdateSmartReport,
} from "../api/Reports.hooks";

import "../../assets/scss/smartReport.scss";

import { components, sharedHelper, data } from "@crewos/shared";

import NewSmartReportModal from "../components/NewSmartReportModal";
import SaveSmartReportModal from "../components/SaveSmartReportModal";
import SelectSmartReportModal from "../components/SelectSmartReportModal";
import SmartReportDetailModal from "../components/SmartReportDetailModal";

const { Loader, TooltipItem, ConfirmationModal } = components;

const { useAuth, AUTH_ACTIONS } = data;

const { AdvanceTable } = components;

const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";

const REPORTS_PACKAGE = "reports";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const getColumns = (report) =>
  report.columns
    .filter((column) => column.enabled)
    .map((column) => ({
      disableSortBy: true,
      accessor: column.accessor,
      header: column.label,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: typeof data === "number" ? "number" : "text",
      Cell: (rowData) => {
        const data = sharedHelper.formatDateTimes(rowData.row[column.accessor]);
        if (typeof data === "number") {
          return data.toFixed(2);
        }
        return data || "-";
      },
    }));

const SmartReports = ({ workOrderId, reportId }) => {
  const [authContext, setAuthContext] = useAuth();

  const { id } = useParams();

  const smartReportsReadOnlyEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    REPORTS_PACKAGE,
    SMART_REPORTS_READ_ONLY
  );

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const isReadOnly = smartReportsReadOnlyEnabled && !isSuperAdmin;

  const [report, setReport] = useState({ data: [] });
  const [selectedReportId, setSelectedReportId] = useState();
  const [prompt, setPrompt] = useState();
  const [query, setQuery] = useState();

  const [refresh, setRefresh] = useState();

  const [newSmartReportModal, setNewSmartReportModal] = useState();
  const [saveSmartReportModal, setSaveSmartReportModal] = useState();
  const [selectSmartReportModal, setSelectSmartReportModal] = useState();
  const [smartReportDetailModal, setSmartReportDetailModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const [columnsTouched, setColumnsTouched] = useState();

  const { data: updateReportData, update: updateReport } =
    useUpdateSmartReport();

  const { data: deleteReportData, deleteReport } = useDeleteSmartReport();

  const {
    data: generateReportData,
    isLoading: isLoadingGenerateReport,
    get: generateReport,
    error: generateReportError,
  } = useGenerateSmartReport();

  const {
    data: selectedReportData,
    isLoading: isLoadingGetReport,
    get: getReport,
  } = useGetSmartReports();

  useEffect(() => {
    if (columnsTouched && report.id) {
      updateReport(report);
    }
  }, [columnsTouched, report, updateReport]);

  useEffect(() => {
    if (deleteReportData) {
      sharedHelper.successToast(`Report deleted`);
    }
  }, [deleteReportData, authContext.userData, report.id, setAuthContext]);

  useEffect(() => {
    if (updateReportData) {
      sharedHelper.successToast(`Report updated`);
      setColumnsTouched();
      setRefresh((prev) => !prev);
    }
  }, [updateReportData]);

  useEffect(() => {
    if (id || reportId) {
      setSelectedReportId();
    }
  }, [setSelectedReportId, id, reportId]);

  useEffect(() => {
    if (prompt || query) {
      generateReport({ prompt, query, workOrderId });
    }
  }, [generateReport, prompt, query, refresh, workOrderId]);

  useEffect(() => {
    if (id) {
      getReport({ id, workOrderId });
    } else if (reportId) {
      getReport({ id: reportId, workOrderId });
    } else if (selectedReportId) {
      getReport({ id: selectedReportId, workOrderId });
    } else {
      setReport({ data: [] });
    }
  }, [getReport, selectedReportId, refresh, id, reportId, workOrderId]);

  useEffect(() => {
    if (selectedReportData) {
      setReport(selectedReportData);
    }
  }, [setReport, selectedReportData]);

  useEffect(() => {
    if (generateReportError) {
      sharedHelper.errorToast(generateReportError);
      setReport({ data: [] });
    } else if (generateReportData) {
      const attributes = Object.keys(generateReportData.data[0] || []);
      const data = generateReportData.data.flatMap((d) => d);
      const hasError = data.some((d) =>
        attributes.some(
          (a) =>
            typeof d[a] !== "string" &&
            typeof d[a] !== "number" &&
            d[a] !== null
        )
      );
      if (hasError) {
        sharedHelper.errorToast(
          `There was an error generating the report, please try again or try with a different prompt.`
        );
        setReport({ data: [] });
      } else {
        setReport(generateReportData);
      }
    }
  }, [generateReportData, setReport, generateReportError]);

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        setConfirmationModal();
        deleteReport(report.id);
        setSelectedReportId();
        setReport({ data: [] });
        setColumnsTouched();
        const userData = { ...authContext.userData };
        const index = userData.smartReports.findIndex(
          (r) => r.id === report.id
        );
        if (index > -1) {
          userData.smartReports.splice(index, 1);
        }
        setAuthContext({
          action: AUTH_ACTIONS.SET_USER_DATA,
          payload: { userData },
        });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Smart Report",
      body: `Are you sure you want to delete this report?`,
      confirmText: "Delete",
    });
  };

  const onColumnSort = (columnOrder) => {
    const columns = report.columns.map((column) => ({
      ...column,
      order: columnOrder.findIndex((c) => c === column.accessor) + 1,
    }));
    setReport({ ...report, columns });
    setColumnsTouched(true);
  };

  const isLoading = isLoadingGenerateReport || isLoadingGetReport;

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          {id || reportId ? (
            <CardHeader className="pt-2 pb-3 d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h2 className="mb-0">{report.name}</h2>
                <small className="text-muted ms-2 pt-1">
                  ({report.data.length})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <Button
                  disabled={isLoading}
                  size="sm"
                  className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  data-testid="refresh-button"
                  onClick={() => setRefresh((prev) => !prev)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
                <div className="table-export-container me-3">
                  <div id="table-export" />
                </div>
              </div>
            </CardHeader>
          ) : (
            <CardHeader className="pt-2 pb-3 d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-start flex-column">
                <div className="d-flex align-items-center">
                  <h2 className="mb-0">
                    {workOrderId ? "" : isReadOnly ? "Preset " : "Smart "}
                    Reports
                  </h2>
                  {!isReadOnly ? (
                    <div className="ms-2">
                      <TooltipItem
                        id="tooltip-1"
                        title={
                          "Build your own AI generated reports using natural language"
                        }
                      >
                        <FontAwesomeIcon
                          className="text-primary cursor-pointer"
                          icon={faInfoCircle}
                        />
                      </TooltipItem>
                    </div>
                  ) : null}
                </div>
                {report?.name ? (
                  <span
                    className="text-link"
                    onClick={() => setSmartReportDetailModal(true)}
                  >
                    {report.name}
                  </span>
                ) : prompt ? (
                  <i>{prompt}</i>
                ) : query ? (
                  <code>{query}</code>
                ) : null}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {!isReadOnly ? (
                  <Button
                    disabled={isLoading}
                    className="me-3"
                    color="primary"
                    onClick={() => setNewSmartReportModal(true)}
                  >
                    New Report
                  </Button>
                ) : null}
                <Button
                  disabled={isLoading}
                  className="me-3"
                  color="primary"
                  onClick={() => setSelectSmartReportModal(true)}
                >
                  Select Report
                </Button>
                {!isReadOnly && (prompt || query) && !generateReportError ? (
                  <Button
                    disabled={isLoading}
                    className="me-3"
                    color="primary"
                    onClick={() => setSaveSmartReportModal(true)}
                  >
                    Save Report
                  </Button>
                ) : !isReadOnly && report.id ? (
                  <>
                    <Button
                      disabled={isLoading}
                      className="me-3 d-none"
                      color="primary"
                      onClick={() => updateReport(report)}
                    >
                      Update Report
                    </Button>
                    <Button
                      disabled={isLoading}
                      className="me-3"
                      color="danger"
                      onClick={onDelete}
                    >
                      Delete Report
                    </Button>
                  </>
                ) : null}
                <Button
                  disabled={isLoading}
                  size="sm"
                  className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  data-testid="refresh-button"
                  onClick={() => setRefresh((prev) => !prev)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
                <div className="table-export-container me-3">
                  <div id="table-export" />
                </div>
              </div>
            </CardHeader>
          )}
          <CardBody className="p-4 border-radius-default overflow-x-auto">
            {isLoading ? (
              <Loader />
            ) : !report.query ? (
              isReadOnly ? (
                <div className="text-center text-muted">
                  Start by selecting a report
                </div>
              ) : (
                <div className="text-center text-muted">
                  Start by generating a new report or selecting an existing one
                </div>
              )
            ) : report.data.length ? (
              <AdvanceTable
                columnsSortable={true}
                key={refresh}
                columns={getColumns(report)}
                data={report.data}
                pageSize={Number.MAX_SAFE_INTEGER}
                exportable
                exportName="custom.csv"
                onColumnSort={onColumnSort}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                }}
                isColumnSortable={!isReadOnly}
                currentColumnOrder={report.columns
                  .sort((x, y) => x.order - y.order)
                  .map((col) => col.accessor)} // Pass columnOrder here
              />
            ) : (
              <div className="text-center text-muted small">
                No data available in the report
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      {newSmartReportModal ? (
        <NewSmartReportModal
          context={workOrderId ? "Work Order" : "Global"}
          onSubmit={(data, from) => {
            if (from === "prompt") {
              setPrompt(data);
              setQuery();
            } else {
              setPrompt();
              setQuery(data);
            }
            setNewSmartReportModal();
            setSelectedReportId();
          }}
          onClose={() => setNewSmartReportModal()}
        />
      ) : saveSmartReportModal ? (
        <SaveSmartReportModal
          report={report}
          workOrderId={workOrderId}
          onSubmit={(report) => {
            setSaveSmartReportModal();
            setSelectedReportId(report.id);
            setPrompt();
            setQuery();
            const userData = { ...authContext.userData };
            const showSidebar =
              report.showSidebar && report.showSidebar !== "false";
            const index = userData.smartReports.findIndex(
              (r) => r.id === report.id
            );
            if (showSidebar) {
              if (index === -1) {
                //add
                userData.smartReports.push(report);
              } else {
                //replace
                userData.smartReports.splice(
                  userData.smartReports.findIndex((r) => r.id === report.id),
                  1,
                  report
                );
              }
            } else if (index !== -1) {
              //remove
              userData.smartReports.splice(index, 1);
            }
            setAuthContext({
              action: AUTH_ACTIONS.SET_USER_DATA,
              payload: { userData },
            });
            setRefresh(!refresh);
          }}
          onClose={() => setSaveSmartReportModal()}
        />
      ) : selectSmartReportModal ? (
        <SelectSmartReportModal
          isReadOnly={isReadOnly}
          workOrderId={workOrderId}
          selectedReportId={report?.id}
          onSubmit={(reportId) => {
            setSelectSmartReportModal();
            setSelectedReportId(reportId);
            setPrompt();
            setQuery();
          }}
          onClose={() => setSelectSmartReportModal()}
        />
      ) : smartReportDetailModal ? (
        <SmartReportDetailModal
          isReadOnly={isReadOnly}
          report={report}
          onSubmit={(report) => {
            setSmartReportDetailModal();
            const userData = { ...authContext.userData };
            const showSidebar =
              report.showSidebar && report.showSidebar !== "false";
            const index = userData.smartReports.findIndex(
              (r) => r.id === report.id
            );
            if (showSidebar) {
              if (index === -1) {
                //add
                userData.smartReports.push(report);
              } else {
                //replace
                userData.smartReports.splice(
                  userData.smartReports.findIndex((r) => r.id === report.id),
                  1,
                  report
                );
              }
            } else if (index !== -1) {
              //remove
              userData.smartReports.splice(index, 1);
            }
            setAuthContext({
              action: AUTH_ACTIONS.SET_USER_DATA,
              payload: { userData },
            });
            setRefresh(!refresh);
          }}
          onClose={() => setSmartReportDetailModal()}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default SmartReports;
