import React, { useCallback, useEffect, useState } from "react";

import { Card, CardHeader, CardBody, Container, Button } from "reactstrap";

import { useParams } from "react-router";

import { useNavigate } from "react-router-dom";

import { faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetWorkOrders } from "../../../workorders/src/api/WorkOrders.hooks";
import WorkOrderModal from "../../../workorders/src/components/WorkOrderModal";

import { components, sharedHelper, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const { AdvanceTable, AdvanceTablePagination } = components;

const columns = [
  {
    accessor: "workOrderNumber",
    header: "Work Order #",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { workOrderNumber } = rowData.row;
      return workOrderNumber || "-";
    },
  },
  {
    accessor: "customer.customerName",
    header: "Customer",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
      style: { maxWidth: 350 },
    },
    Cell: (rowData) => {
      const { customer } = rowData.row;
      return customer?.customerName || "-";
    },
  },
  {
    accessor: "customerLocation.shipToAddress",
    header: "Location",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { customerLocation } = rowData.row;
      return customerLocation?.shipToAddress || "-";
    },
  },
  {
    accessor: "crews",
    header: "Lead",
    headerProps: { className: "text-truncate", style: { maxWidth: 250 } },
    cellProps: {
      className: "text-truncate",
      style: { maxWidth: 250 },
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { crews } = rowData.row;
      const leads = crews.flatMap((crew) =>
        crew.employeeCrews.flatMap((employeeCrew) => employeeCrew.employee)
      );
      return leads.length
        ? leads.map((lead) => `${lead.firstName} ${lead.lastName}`).join(", ")
        : "-";
    },
  },
  {
    accessor: "workOrderStatus.name",
    header: "Status",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { workOrderStatus } = rowData.row;
      return workOrderStatus.name;
    },
  },
  {
    accessor: "startDate",
    header: "Dates",
    headerProps: { className: "text-truncate", style: { minWidth: 250 } },
    Cell: (rowData) => {
      const { startDate, endDate } = rowData.row;
      return `${sharedHelper.formatDate(
        startDate,
        "YYYY/MM/DD"
      )} - ${sharedHelper.formatDate(endDate, "YYYY/MM/DD")}`;
    },
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 1;

const WorkOrders = () => {
  const [authContext] = useAuth();

  const [workOrders, setWorkOrders] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("workOrderNumber");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();
  const { workOrderStatusId } = useParams();

  const [createModal, setCreateModal] = useState();

  const {
    data: workOrdersData,
    isLoading: isLoadingWorkOrders,
    get: getWorkOrders,
  } = useGetWorkOrders();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getWorkOrders({
      search: search,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy,
      direction,
      workOrderStatusId,
    });
  }, [
    workOrderStatusId,
    getWorkOrders,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
  ]);

  useEffect(() => {
    if (workOrdersData) {
      setWorkOrders(workOrdersData);
    }
  }, [workOrdersData, setWorkOrders]);

  const onSeeDetails = (entry) => navigate(`/workorders/details/${entry.id}`);

  const status = authContext.userData.workOrderStatus?.find(
    (s) => s.id === workOrderStatusId
  );

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex align-items-center">
                <h2 className="mb-0">{status?.name || "All"} Work Orders</h2>
                <span className="text-muted ms-2 pt-1">
                  ({workOrders.count})
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search for.."
                  value={search}
                  onChange={(evt) => {
                    setSearch(evt.target.value);
                    setPage(INIT_PAGE);
                  }}
                />
              </div>
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={() => setRefresh((prev) => !prev)}
                data-testid="refresh-button"
              >
                <FontAwesomeIcon icon={faSync} />
              </Button>
              <div className="table-export-container me-3">
                <div id="table-export" />
              </div>
              <Button color="primary" onClick={() => setCreateModal(true)}>
                Create
              </Button>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            <div className="overflow-x-auto">
              <AdvanceTable
                exportable
                onRowClick={onSeeDetails}
                exportName="WorkOrders.csv"
                columns={columns}
                data={workOrders.data || []}
                pageSize={sizePerPage}
                sortable
                onSort={onSort}
                defaultSort={{
                  sortBy,
                  direction,
                }}
                isLoading={isLoadingWorkOrders}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                }}
              />
            </div>
            <AdvanceTablePagination
              totalCount={workOrders.count}
              pageCount={workOrders.totalPages}
              currentPage={page - 1}
              onPageChange={(page) => setPage(page)}
              pageSize={sizePerPage}
              onPageSizeChange={(sizePerPage) => {
                setSizePerPage(sizePerPage);
                setPage(INIT_PAGE);
              }}
            />
          </CardBody>
        </Card>
      </div>
      {createModal ? (
        <WorkOrderModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkOrders;
