import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
} from "reactstrap";

import DatePicker from "react-datepicker";

import {
  crewHelper,
  useCrews,
  useCrewWorkDays,
  CREW_WORK_DAY_ACTIONS,
} from "@crewos/crews";

import moment from "moment";

import { useCreateCrewWorkDay } from "../api/CrewWorkDays.hooks";

import { components, data, sharedHelper } from "@crewos/shared";

const { useWorkOrderDetails, useAuth } = data;

const { Loader } = components;

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const AddWorkDayModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewsContext] = useCrews();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);

  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );

  const {
    isLoading: isLoadingCreateWorkDay,
    mutate: createWorkDay,
    data: createWorkDayData,
  } = useCreateCrewWorkDay();

  useEffect(() => {
    if (createWorkDayData) {
      sharedHelper.successToast(`Work day created`);
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
      onClose();
    }
  }, [createWorkDayData, setCrewWorkDaysContext, onClose]);

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const doSubmit = async (event) => {
    event.preventDefault();
    const crewWorkDays =
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] || [];
    const crewWorkDay = crewWorkDays.find((cwd) => cwd.crewId === crew.id);
    if (crewWorkDay) {
      return sharedHelper.warningToast(
        `A work day already exists for the selected date and crew.`
      );
    }
    createWorkDay({ date: selectedDate, crewId: crew.id });
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date ? moment(date).format("YYYY-MM-DD") : null);
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Add Work Day
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateWorkDay ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={onSelectedDate}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Crew</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                {crewsWithLeadForDate.length ? (
                  <select
                    className="form-control-redesign px-0"
                    required={true}
                    type="select"
                    name="crewSelect"
                    id="crewSelect"
                    data-testid="crew-select"
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crew = crewsContext.crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>Select a crew</option>
                    {crewsWithLeadForDate.map((crew) => {
                      const lead = crewHelper.getCrewLead(
                        employeeCrewPastDatesEnabled,
                        crew
                      );
                      return (
                        <option key={crew.id} value={crew.id}>
                          {`${lead.employee.firstName} ${lead.employee.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="text-muted small">
                    No crews for selected date
                  </div>
                )}
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkDay ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddWorkDayModal;
