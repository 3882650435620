import React, { useState } from "react";

import { Button, Col, Row, Card, CardBody } from "reactstrap";

import { data, components } from "@crewos/shared";

import CrewWorkDay from "./CrewWorkDay";
import AddExpenseModal from "./AddExpenseModal";

import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";
import ExpensesCards from "./ExpensesCards";

const { WorkOrderDateSelector, Loader } = components;

const { useWorkOrderDetails } = data;

const CrewsExpenses = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [addExpenseModal, setAddExpenseModal] = useState(false);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 mb-3 px-0 min-height-200-px">
          <CardBody className="border-top-left-radius-0">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="mb-0">Expenses</h2>
              <Button color="primary" onClick={() => setAddExpenseModal(true)}>
                Add Expense
              </Button>
            </div>
            <ExpensesCards />
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {workOrderDetails.loading ? (
          <Card className="w-100">
            <CardBody className="py-3">
              <Loader size="sm" />
            </CardBody>
          </Card>
        ) : !crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate] ? (
          <Card className="w-100 px-0">
            <CardBody className="py-3">
              <div className="text-center text-muted small">
                No data to display
              </div>
            </CardBody>
          </Card>
        ) : (
          crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate].map(
            (crewWorkDay) => (
              <CrewWorkDay key={crewWorkDay.id} crewWorkDay={crewWorkDay} />
            )
          )
        )}
      </Row>
      {addExpenseModal ? (
        <AddExpenseModal
          onSubmit={() => {
            setAddExpenseModal(false);
            setCrewWorkDaysContext({
              action: CREW_WORK_DAY_ACTIONS.REFRESH,
            });
          }}
          onClose={() => setAddExpenseModal(false)}
        />
      ) : null}
    </Col>
  );
};

export default CrewsExpenses;
