// SidebarItem.js
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sharedHelper } from "../../helpers/sharedHelper";
import { useAuth } from "../../providers/authProvider";

const SidebarItem = ({
  isLast,
  name,
  badgeColor,
  badgeText,
  icon,
  path,
  action,
  pkg,
  setting,
  isAction,
  className = "",
}) => {
  const [authContext] = useAuth();
  const location = useLocation();

  const isActive = location.pathname === path;

  let routeName = name;
  if (
    setting &&
    sharedHelper.isSettingEnabled(authContext.userData?.packages, pkg, setting)
  ) {
    routeName =
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        pkg,
        setting
      ) || name;
  }

  const content = (
    <>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          fixedWidth
          data-testid="sidebar-icon"
          className={`align-middle mx-2 sidebar-icon ${
            isActive ? "text-primary" : ""
          }`}
        />
      )}
      <span className="align-middle text-truncate ms-1">{routeName}</span>
      {badgeColor && badgeText && (
        <Badge color={badgeColor} size={18} className="sidebar-badge">
          {badgeText}
        </Badge>
      )}
    </>
  );

  if (isAction) {
    return (
      <div
        data-testid="sidebar-item-action"
        className={`${isLast ? "" : "mb-1"} sidebar-item ${
          isActive ? "active" : ""
        } ${className}`}
        onClick={action}
      >
        <div className="sidebar-link text-truncate py-2" role="button">
          {content}
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid="sidebar-item"
      className={`${isLast ? "" : "mb-1"} sidebar-item ${
        isActive ? "active" : ""
      } ${className}`}
    >
      <NavLink
        to={path}
        className={({ isActive }) =>
          `sidebar-link text-truncate py-2 ${isActive ? "active-style" : ""}`
        }
      >
        {content}
      </NavLink>
    </div>
  );
};

export default SidebarItem;
