import React, { useState } from "react";

import { Row, Card, CardBody, Button, Col } from "reactstrap";

import CrewModal from "./CrewModal";
import { ACTIONS, useCrews } from "../providers/crewsProvider";
import Crew from "./Crew";
import { data, sharedHelper } from "@crewos/shared";

const { useAuth } = data;

const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const CREWS_PACKAGE = "crews";

const WorkOrderCrews = () => {
  const [crewsContext, setCrewsContext] = useCrews();
  const [authContext] = useAuth();

  const [crewModal, setCrewModal] = useState();

  const isSingleCrewEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    SINGLE_CREW
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 mb-3 px-0">
          <CardBody className="border-top-left-radius-0">
            <div className="d-flex justify-content-between align-items-center">
              <h2>
                {directAssignationEnabled
                  ? "Assignments"
                  : isSingleCrewEnabled
                  ? "Crew"
                  : "Crews"}
              </h2>
              <div className="d-flex">
                {!isSingleCrewEnabled || !crewsContext.crews?.length ? (
                  <Button color="primary" onClick={() => setCrewModal(true)}>
                    Add {directAssignationEnabled ? "Assignment" : "Crew"}
                  </Button>
                ) : null}
              </div>
            </div>
            {!crewsContext.crews?.length ? (
              <div className="text-center text-muted mt-4 small">
                No data to display
              </div>
            ) : (
              crewsContext.crews.map((crew) => (
                <Crew key={crew.id} crew={crew} />
              ))
            )}
            {crewModal ? (
              <CrewModal
                onClose={() => setCrewModal(false)}
                onSubmit={() => {
                  setCrewModal(false);
                  setCrewsContext({
                    action: ACTIONS.REFRESH,
                  });
                }}
              />
            ) : null}
          </CardBody>
        </Card>
      </Row>
    </Col>
  );
};

export default WorkOrderCrews;
