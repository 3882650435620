import React, { useCallback, useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Input,
  FormFeedback,
} from "reactstrap";

const DeleteModal = ({ isOpen, name, onClose, onDelete, title, isLoading }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [text, setText] = useState("");

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (text.toLowerCase() === "delete") {
        setText("");
        onDelete();
      } else {
        setIsInvalid(true);
      }
    },
    [onDelete, text]
  );

  const handleOnChange = useCallback((event) => {
    setIsInvalid(false);
    setText(event.target.value);
  }, []);

  return (
    <Modal isOpen={isOpen} size="sm">
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={onClose} className="border-bottom-0 text-lg">
          {title || `Delete ${name}`}
        </ModalHeader>
        <ModalBody>
          <p className="text-danger text-sm">
            This action can not be reversed:
          </p>
          <p className="fw-bold">{name}</p>
          <div>
            <FormGroup>
              <Label for="confirm-delete">
                Type the word <strong>DELETE</strong> to confirm{" "}
                <span className="text-danger">*</span>
              </Label>
              <Input
                id="confirm-delete"
                name="confirm-delete"
                maxLength="6"
                type="text"
                className="form-control-line"
                required
                invalid={isInvalid}
                onChange={handleOnChange}
                value={text}
              />
              <FormFeedback>Please type DELETE to confirm.</FormFeedback>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button color="primary" type="submit" disabled={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DeleteModal;
