import React, { useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CurrencyInput from "react-currency-input-field";
import { sharedHelper } from "@crewos/shared";

import { Button, Label } from "reactstrap";

import { crewHelper } from "@crewos/crews";

import { useGetAllExpenseTypes } from "../api/ExpenseTypes.hooks";

import { components, data } from "@crewos/shared";

const { Loader } = components;

const { useAuth } = data;

const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const CrewMembers = ({
  crew,
  selectedDate,
  paymentTypeToAdd,
  setPaymentTypeToAdd,
  descriptionToAdd,
  setDescriptionToAdd,
  expenseTypeToAdd,
  setExpenseTypeToAdd,
  employeeEnabled,
  setEmployeeEnabled,
  amountToAdd,
  setAmountToAdd,
}) => {
  const [authContext] = useAuth();
  const [expenseTypes, setExpenseTypes] = useState([]);

  const {
    isLoading: isLoadingGetExpenseTypes,
    get: getExpenseTypes,
    data: expenseTypesData,
  } = useGetAllExpenseTypes();

  useEffect(() => {
    getExpenseTypes();
  }, [getExpenseTypes]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const lead = crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew);

  return (
    <div className="mt-3">
      <div className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default">
        {`${lead.employee.firstName} ${lead.employee.lastName}`}
      </div>
      {crew.employeeCrews
        .filter((employeeCrew) =>
          crewHelper.isEmployeeCrewActiveOnDate(
            employeeCrewPastDatesEnabled,
            employeeCrew,
            selectedDate
          )
        )
        .sort(sharedHelper.sortEmployeeCrew)
        .map((employeeCrew) => (
          <div
            key={employeeCrew.id}
            className="mt-1 d-flex justify-content-between align-items-start p-2 border-bottom col-12"
            tag="div"
          >
            <div className="d-flex flex-column align-items-start flex-grow-1">
              <small>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</small>
              <small className="text-muted text-left">{`${employeeCrew.role.name}`}</small>
            </div>
            <div className="ms-5 flex-grow-1 d-flex justify-content-end align-items-center align-self-center">
              {employeeEnabled[employeeCrew.employee.id] ? (
                <div className="col-12 px-0 d-flex flex-column align-items-start">
                  <div
                    className="d-flex justify-content-center align-items-center pb-2 cursor-pointer col-12 px-0 position-relative"
                    tag="div"
                  >
                    {isLoadingGetExpenseTypes ? (
                      <Loader size="sm" />
                    ) : (
                      <div
                        className="d-flex align-items-start flex-column col-12 px-0"
                        tag="div"
                      >
                        <Label
                          className="d-flex"
                          htmlFor={`expenseTypesSelect-${employeeCrew.employee.id}`}
                        >
                          <small>Type</small>
                          <small className="ms-1 text-danger">*</small>
                        </Label>
                        <select
                          className="form-control-redesign"
                          type="select"
                          name={`expenseTypesSelect-${employeeCrew.employee.id}`}
                          id={`expenseTypesSelect-${employeeCrew.employee.id}`}
                          value={
                            expenseTypeToAdd[employeeCrew.employee.id] || ""
                          }
                          onChange={(event) =>
                            setExpenseTypeToAdd({
                              ...expenseTypeToAdd,
                              [employeeCrew.employee.id]:
                                event.currentTarget.value,
                            })
                          }
                        >
                          <option value={""}>Select type...</option>
                          {expenseTypes.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 999,
                      }}
                      onClick={() =>
                        setEmployeeEnabled({
                          ...employeeEnabled,
                          [employeeCrew.employee.id]: false,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="cursor-pointer text-danger bg-white"
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 cursor-pointer col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`paymentTypeSelect-${employeeCrew.employee.id}`}
                      >
                        <small>Payment Method</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <select
                        className="form-control-redesign"
                        required={true}
                        type="select"
                        name={`paymentTypeSelect-${employeeCrew.employee.id}`}
                        id={`paymentTypeSelect-${employeeCrew.employee.id}`}
                        value={paymentTypeToAdd[employeeCrew.employee.id] || ""}
                        onChange={(event) =>
                          setPaymentTypeToAdd({
                            ...paymentTypeToAdd,
                            [employeeCrew.employee.id]:
                              event.currentTarget.value,
                          })
                        }
                      >
                        <option value={""}>Select payment...</option>
                        <option value={PAYMENT_TYPE_CASH}>Cash</option>
                        <option value={PAYMENT_TYPE_CARD}>Card</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 cursor-pointer col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`amount-${employeeCrew.employee.id}`}
                      >
                        <small>Amount</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <CurrencyInput
                        data-testid={`amount-${employeeCrew.employee.id}`}
                        className="form-control-redesign"
                        required={true}
                        id={`amount-${employeeCrew.employee.id}`}
                        name={`amount-${employeeCrew.employee.id}`}
                        defaultValue={
                          amountToAdd[employeeCrew.employee.id] || ""
                        }
                        prefix="$"
                        decimalLimit={2}
                        max={999999999}
                        allowNegativeValue={false}
                        placeholder="Enter the amount.."
                        onValueChange={(value, name, values) =>
                          setAmountToAdd({
                            ...amountToAdd,
                            [employeeCrew.employee.id]: parseFloat(value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 cursor-pointer col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`description-${employeeCrew.employee.id}`}
                      >
                        <small>Description</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <input
                        className="form-control-redesign"
                        required={true}
                        rows={4}
                        placeholder="Enter the description .."
                        type="textarea"
                        id={`description-${employeeCrew.employee.id}`}
                        value={descriptionToAdd[employeeCrew.employee.id] || ""}
                        onChange={(event) =>
                          setDescriptionToAdd({
                            ...descriptionToAdd,
                            [employeeCrew.employee.id]:
                              event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() =>
                    setEmployeeEnabled({
                      ...employeeEnabled,
                      [employeeCrew.employee.id]: true,
                    })
                  }
                >
                  Add Expense
                </Button>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default CrewMembers;
