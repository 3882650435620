import React, { useState, useEffect } from "react";
import { data, sharedHelper, components } from "@crewos/shared";
import { Card, CardBody } from "reactstrap";
import { useGetExpenses } from "../api/Expenses.hooks";

const { Loader } = components;
const { useWorkOrderDetails } = data;

const ExpensesCards = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [expenses, setExpenses] = useState([]);

  const {
    data: getExpensesData,
    isLoading: isLoadingGetExpenses,
    get: getExpenses,
  } = useGetExpenses();

  useEffect(() => {
    getExpenses({
      workOrderId: workOrderDetails.workOrder.id,
    });
  }, [getExpenses, workOrderDetails]);

  useEffect(() => {
    if (getExpensesData) {
      setExpenses(getExpensesData);
    }
  }, [getExpensesData, setExpenses]);

  const totalExpenses = expenses.reduce((p, c) => p + c.amount, 0);
  const dateExpenses = expenses
    .filter((expense) => expense.date === workOrderDetails.selectedDate)
    .reduce((p, c) => p + c.amount, 0);

  return isLoadingGetExpenses ? (
    <Loader size="sm" className="h-25" />
  ) : (
    <div className="d-flex justify-content-start align-items-center p-3 work-performed-cards overflow-auto">
      <Card
        className="flex-grow-1 flex-shrink-0 mb-0 me-4"
        data-testid="total-expenses-card"
      >
        <CardBody className="pb-3">
          <h1
            className="mb-0 text-center currency"
            data-testid="total-expenses-value"
          >
            {sharedHelper.formatCurrency(totalExpenses)}
          </h1>
          <div className="mt-1 text-center small text-truncate">
            total expenses
          </div>
        </CardBody>
      </Card>
      <Card className="flex-grow-1 mb-0 me-4" data-testid="day-expenses-card">
        <CardBody className="pb-3">
          <h1
            className="mb-0 text-center currency"
            data-testid="day-expenses-value"
          >
            {sharedHelper.formatCurrency(dateExpenses)}
          </h1>
          <div className="mt-1 text-center small text-truncate">
            day expenses
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ExpensesCards;
