import React, { useState, useEffect } from "react";
import { Row, Button, Card, CardBody } from "reactstrap";
import { useGetNotes } from "../api/Notes.hooks";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { data, sharedHelper, components } from "@crewos/shared";
import Note from "./Note";
import NoteModal from "./NoteModal";

const { useAuth } = data;
const { Loader } = components;

const CrewWorkDayNotes = ({ crewWorkDay }) => {
  const [refresh, setRefresh] = useState();
  const [notes, setNotes] = useState([]);

  const [createModal, setCreateModal] = useState(false);

  const {
    data: getNotesData,
    isLoading: isLoadingGetNotes,
    get: getNotes,
  } = useGetNotes();

  useEffect(() => {
    getNotes({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getNotes, refresh, crewWorkDay]);

  useEffect(() => {
    if (getNotesData) {
      setNotes(getNotesData);
    }
  }, [getNotesData]);

  return (
    <Card className="w-100 mb-3 px-0">
      <CardBody>
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Notes</h3>
          <div className="d-flex">
            <Button
              size="sm"
              color="primary"
              onClick={() => setCreateModal(true)}
            >
              Add
            </Button>
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </div>
        {isLoadingGetNotes ? (
          <Loader size="sm" />
        ) : notes.length ? (
          <Row className="mt-3 d-flex flex-wrap">
            {notes.map((note) => (
              <div className="col-6" key={note.id}>
                <Note
                  note={note}
                  onRefresh={() => setRefresh((prev) => !prev)}
                />
              </div>
            ))}
          </Row>
        ) : (
          <div className="p-2 small text-muted text-center">No notes</div>
        )}
      </CardBody>
      {createModal && (
        <NoteModal
          crewWorkDayId={crewWorkDay.id}
          onSubmit={() => {
            setCreateModal(false);
            sharedHelper.successToast("Note created");
            setRefresh((prev) => !prev);
          }}
          onClose={() => setCreateModal(false)}
        />
      )}
    </Card>
  );
};

const CrewWorkDayNotesWrapper = (props) => {
  const [authContext] = useAuth();

  if (!sharedHelper.isPackageEnabled(authContext.userData?.packages, "notes")) {
    return null;
  }

  return <CrewWorkDayNotes {...props} />;
};

export default CrewWorkDayNotesWrapper;
