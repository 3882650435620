import React, { useEffect } from "react";

import { data } from "@crewos/shared";

import { Navigate, Outlet } from "react-router-dom";
import Packages from "../pages/settings/Packages";
import Core from "../pages/settings/Core";

const { useAuth, useSidebar, SIDEBAR_ACTIONS } = data;

const PACKAGE = "core";

// Routes
export const settingsRoutes = [
  {
    icon: null,
    name: "Modules",
    path: `core/packages`,
    package: "core",
    sidebar: true,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    category: "General",
    component: Packages,
    order: 1,
  },
  {
    icon: null,
    name: "Core",
    path: `core`,
    package: "core",
    sidebar: true,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    category: "General",
    component: Core,
    order: 2,
  },
];

export const SettingsWrapper = () => {
  const [authContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  useEffect(() => {
    if (
      !sidebarContext.settingsRoutes.find((route) => route.package === PACKAGE)
    ) {
      setSidebarContext({
        action: SIDEBAR_ACTIONS.SET_ROUTES,
        payload: { routes: settingsRoutes },
      });
    }
  }, [sidebarContext.settingsRoutes, sidebarContext.routes, setSidebarContext]);

  return !authContext.userData ? <Navigate to={`/auth/sign-in`} /> : <Outlet />;
};
