import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";

const ACTIONS = {
  RESET: "RESET",
  REFRESH: "REFRESH",
  CHANGE_TAB: "CHANGE_TAB",
  SELECT_DATE: "SELECT_DATE",
  START_TRANSITION: "START_TRANSITION",
  GET_WORK_ORDER_SUCCESS: "GET_WORK_ORDER_SUCCESS",
  GET_WORK_ORDER_STATUS_SUCCESS: "GET_WORK_ORDER_STATUS_SUCCESS",
};

const initialState = {
  workOrderStatus: [],
  workOrder: null,
  activeTab: null,
  selectedDate: null,
  loading: null,
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORK_ORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder = {} } = payload;
      const isDifferentWorkOrder = workOrder.id !== state.workOrder?.id;
      const activeTab = isDifferentWorkOrder
        ? JOB_DETAILS_TAB
        : state.activeTab || JOB_DETAILS_TAB;

      return {
        ...state,
        workOrder,
        activeTab,
        selectedDate: moment().format("YYYY-MM-DD"),
      };
    }

    case ACTIONS.GET_WORK_ORDER_STATUS_SUCCESS: {
      const { payload } = data;
      const { workOrderStatus } = payload;

      return {
        ...state,
        workOrderStatus,
      };
    }

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.RESET: {
      const newState = {
        ...initialState,
        refresh: state.refresh,
        activeTab: state.activeTab,
      };

      return { ...newState };
    }

    case ACTIONS.CHANGE_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab,
      };
    }

    case ACTIONS.SELECT_DATE: {
      const { payload } = data;
      const { date } = payload;

      return {
        ...state,
        loading: false,
        selectedDate: date,
      };
    }

    case ACTIONS.START_TRANSITION: {
      return {
        ...state,
        loading: true,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const WorkOrderDetailsContext = createContext(initialState);

const WorkOrderDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrderDetailsContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrderDetailsContext.Provider>
  );
};

export const useWorkOrderDetails = () => useContext(WorkOrderDetailsContext);

export { WorkOrderDetailsContext, WorkOrderDetailsProvider, ACTIONS };
