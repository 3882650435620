// Sidebar.js
import React, { useEffect, useMemo } from "react";
import { faSignOutAlt, faCog } from "@fortawesome/free-solid-svg-icons";
import SideBarUser from "./SidebarUser";
import SidebarItem from "./SidebarItem";
import SidebarCategory from "./SidebarCategory";
import brand from "../../assets/img/logoPrimary.png";
import poweredSidebar from "../../assets/img/powered_sidebar.png";
import { useNavigate } from "react-router-dom";
import {
  useSidebar,
  ACTIONS as SIDEBAR_ACTIONS,
} from "../../providers/sidebarProvider";
import { useAuth, ACTIONS as AUTH_ACTIONS } from "../../providers/authProvider";
import { authApi } from "../../api/authServices";
import { sharedHelper } from "../../helpers/sharedHelper";

const Sidebar = () => {
  const navigate = useNavigate();
  const [authContext, setAuthContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const logout = () => {
    authApi.logout();
    setAuthContext({ action: AUTH_ACTIONS.REFRESH });
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.RESET_ROUTES,
    });
  }, [authContext.userData?.packages, setSidebarContext]);

  const filteredRoutes = Object.values(sidebarContext.routes)
    .flatMap((routeGroup) => routeGroup.routes)
    .filter(
      (route) =>
        route.sidebar &&
        (route.setting
          ? sharedHelper.isSettingEnabled(
              authContext.userData?.packages,
              route.package,
              route.setting
            )
          : true) &&
        sharedHelper.routeMeetsRole(
          route,
          authContext.userData?.role.userRoleScopes
        )
    )
    .sort((a, b) => (a.order || 0) - (b.order || 0));

  const settingsRoutes = useMemo(() => {
    return filteredRoutes.filter((route) => route.isSetting);
  }, [filteredRoutes]);

  const mainRoutes = useMemo(() => {
    return filteredRoutes.filter((route) => !route.isSetting);
  }, [filteredRoutes]);

  return (
    <nav className={`sidebar ${!sidebarContext.isOpen ? "toggled" : ""}`}>
      <div className="sidebar-content py-1">
        <div className="py-1 mb-2 sidebar-brand d-flex flex-column align-items-center justify-content-center px-0">
          <img
            src={authContext.userData?.logo || brand}
            className="rounded m-2 col-12"
            alt={process.env.REACT_APP_PROJECT_NAME}
          />
          <h4>{process.env.REACT_APP_PROJECT_NAME}</h4>
        </div>
        <SideBarUser />
        <div className="sidebar-nav d-flex flex-column p-4 justify-content-between">
          <div className="d-flex flex-column">
            {mainRoutes.map((route, index) =>
              route.entries ? (
                <SidebarCategory
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  path={route.path}
                  pkg={route.package}
                  entries={route.entries}
                />
              ) : (
                <SidebarItem
                  key={index}
                  name={route.name}
                  icon={route.icon}
                  path={route.path}
                  pkg={route.package}
                  setting={route.setting}
                />
              )
            )}
            {settingsRoutes.length > 0 && (
              <SidebarCategory
                name="Settings"
                icon={faCog}
                path="/settings"
                entries={settingsRoutes}
              />
            )}
            <SidebarItem
              name="Logout"
              icon={faSignOutAlt}
              action={logout}
              isAction
            />
          </div>
          <div className="d-flex flex-column mx-auto py-2 align-items-end">
            <img
              alt={"CrewOS"}
              src={poweredSidebar}
              style={{ maxWidth: 100 }}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
