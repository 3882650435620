import React from "react";
import { Nav, TabContent, TabPane } from "reactstrap";

import { data, sharedHelper } from "@crewos/shared";

import WorkOrderJobDetails from "./WorkOrderJobDetails";

import {
  WorkOrderCrews,
  WorkOrderCrewsTimes,
  WorkOrderCrewsEquipments,
  WorkOrderCrewsExpenses,
  WorkOrderCrewsPerDiems,
  WorkOrderCrewsInspections,
  WorkOrderCrewsInspectionsTemplates,
} from "@crewos/crews";

import { SmartReports } from "@crewos/reports";
import WorkOrderJobDescription from "./WorkOrderJobDescription";
import NavItemTab from "./NavItemTab";

const { useAuth, useWorkOrderDetails, WORK_ORDER_DETAILS_ACTIONS } = data;

const REPORTS_PACKAGE = "reports";
const SMART_REPORTS_READ_ONLY = "SMART_REPORTS_READ_ONLY";
const SMART_REPORTS_IN_SIDEBAR = "SMART_REPORTS_IN_SIDEBAR";

const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";
const JOB_DESCRIPTION_DETAILS_TAB = "JOB_DESCRIPTION_DETAILS_TAB";
const CREW_TAB = "CREW_TAB";
const TIME_TAB = "TIME_TAB";
const EXPENSES_TAB = "EXPENSES_TAB";
const PER_DIEMS_TAB = "PER_DIEMS_TAB";
const EQUIPMENTS_TAB = "EQUIPMENTS_TAB";
const REPORTS_TAB = "REPORTS_TAB";
const SMART_REPORTS_TAB = "SMART_REPORTS_TAB";
const INSPECTIONS_TAB = "INSPECTIONS_TAB";
const INSPECTIONS_LIST_TAB = "INSPECTIONS_LIST_TAB";
const INSPECTIONS_TEMPLATE_TAB = "INSPECTIONS_TEMPLATE_TAB";

const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const CREWS_PACKAGE = "crews";

const WorkOrderDetailsTabs = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const isSingleCrewEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    SINGLE_CREW
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const smartReportsInSidebarEnabled =
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      REPORTS_PACKAGE,
      SMART_REPORTS_IN_SIDEBAR
    ) || isSuperAdmin;

  const smartReportsReadOnlyEnabled =
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      REPORTS_PACKAGE,
      SMART_REPORTS_READ_ONLY
    ) && !isSuperAdmin;

  const smartReports = authContext.userData.smartReports.filter(
    (report) => report.context === "WORKORDER"
  );

  const toggle = (tab) => {
    if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.CHANGE_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  return (
    <div className="tab tab-vertical h-100">
      <Nav tabs className="cursor-pointer me-3 min-width-200">
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={toggle}
          label="Work Performed"
          tab={JOB_DETAILS_TAB}
        />
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={toggle}
          label="Job Description"
          tab={JOB_DESCRIPTION_DETAILS_TAB}
        />
        <NavItemTab
          workOrderDetails={workOrderDetails}
          toggle={toggle}
          label={
            directAssignationEnabled
              ? "Assignments"
              : isSingleCrewEnabled
              ? "Crew"
              : "Crews"
          }
          tab={CREW_TAB}
        />
        {sharedHelper.isPackageEnabled(authContext.userData?.packages, [
          "worktimes",
          "traveltimes",
        ]) ? (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Time"
            tab={TIME_TAB}
          />
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "expenses"
        ) ? (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Expenses"
            tab={EXPENSES_TAB}
          />
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "perdiems"
        ) ? (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Per Diem"
            tab={PER_DIEMS_TAB}
          />
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "equipments"
        ) ? (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Equipment"
            tab={EQUIPMENTS_TAB}
          />
        ) : null}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "inspections"
        ) && (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Inspections"
            tab={INSPECTIONS_TAB}
            childs={[
              {
                label: "Inspections List",
                tab: INSPECTIONS_LIST_TAB,
              },
              {
                label: "Templates",
                tab: INSPECTIONS_TEMPLATE_TAB,
              },
            ]}
          />
        )}
        {sharedHelper.isPackageEnabled(
          authContext.userData?.packages,
          "reports"
        ) ? (
          <NavItemTab
            workOrderDetails={workOrderDetails}
            toggle={toggle}
            label="Reports"
            tab={REPORTS_TAB}
            childs={[
              smartReportsInSidebarEnabled
                ? {
                    label: `${
                      smartReportsReadOnlyEnabled ? "Preset" : "Smart"
                    } Reports`,
                    tab: SMART_REPORTS_TAB,
                  }
                : false,
              ...smartReports.map((report) => ({
                label: report.name,
                tab: report.id,
                key: report.id,
              })),
            ].filter(Boolean)}
          />
        ) : null}
      </Nav>
      <TabContent
        activeTab={workOrderDetails.activeTab}
        className="h-100 p-0 box-shadow-none"
      >
        <TabPane tabId={JOB_DETAILS_TAB}>
          {workOrderDetails.activeTab === JOB_DETAILS_TAB ? (
            <WorkOrderJobDetails />
          ) : null}
        </TabPane>
        <TabPane tabId={JOB_DESCRIPTION_DETAILS_TAB}>
          {workOrderDetails.activeTab === JOB_DESCRIPTION_DETAILS_TAB ? (
            <WorkOrderJobDescription />
          ) : null}
        </TabPane>
        <TabPane tabId={CREW_TAB}>
          {workOrderDetails.activeTab === CREW_TAB ? <WorkOrderCrews /> : null}
        </TabPane>
        <TabPane tabId={TIME_TAB}>
          {workOrderDetails.activeTab === TIME_TAB ? (
            <WorkOrderCrewsTimes />
          ) : null}
        </TabPane>
        <TabPane tabId={EXPENSES_TAB}>
          {workOrderDetails.activeTab === EXPENSES_TAB ? (
            <WorkOrderCrewsExpenses />
          ) : null}
        </TabPane>
        <TabPane tabId={PER_DIEMS_TAB}>
          {workOrderDetails.activeTab === PER_DIEMS_TAB ? (
            <WorkOrderCrewsPerDiems />
          ) : null}
        </TabPane>
        <TabPane tabId={EQUIPMENTS_TAB}>
          {workOrderDetails.activeTab === EQUIPMENTS_TAB ? (
            <WorkOrderCrewsEquipments />
          ) : null}
        </TabPane>
        <TabPane tabId={SMART_REPORTS_TAB}>
          {workOrderDetails.activeTab === SMART_REPORTS_TAB ? (
            <SmartReports workOrderId={workOrderDetails.workOrder.id} />
          ) : null}
        </TabPane>
        <TabPane tabId={INSPECTIONS_LIST_TAB}>
          {workOrderDetails.activeTab === INSPECTIONS_LIST_TAB ? (
            <WorkOrderCrewsInspections />
          ) : null}
        </TabPane>
        <TabPane tabId={INSPECTIONS_TEMPLATE_TAB}>
          {workOrderDetails.activeTab === INSPECTIONS_TEMPLATE_TAB ? (
            <WorkOrderCrewsInspectionsTemplates />
          ) : null}
        </TabPane>
        {smartReports.map((report) => (
          <TabPane key={report.id} tabId={report.id}>
            {workOrderDetails.activeTab === report.id && (
              <SmartReports
                workOrderId={workOrderDetails.workOrder.id}
                reportId={report.id}
              />
            )}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default WorkOrderDetailsTabs;
