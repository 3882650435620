import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckboxWithLabel = ({
  label,
  onClick,
  checked,
  color = "primary",
  className = "text-muted",
  reverse,
}) => {
  return (
    <div
      className={`d-flex cursor-pointer w-100 justify-content-between align-items-center ${className}`}
      onClick={() => (onClick ? onClick() : null)}
    >
      {label ? (
        <div
          className={`m${reverse ? "s" : "e"}-2`}
          style={{ order: reverse ? 2 : 1 }}
        >
          {label}
        </div>
      ) : null}
      <div
        style={{ order: reverse ? 1 : 2 }}
        className={`bg-${color} p-1 rounded d-flex align-items-center`}
      >
        {checked ? (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-white"
            size="sm"
            data-testid="checked-icon"
          />
        ) : (
          <FontAwesomeIcon
            icon={faCheck}
            className={`text-${color}`}
            size="sm"
            data-testid="unchecked-icon"
          />
        )}
      </div>
    </div>
  );
};

export default CustomCheckboxWithLabel;
