import React from "react";

import CrewWorkDayEquipments from "./CrewWorkDayEquipments";
import { CrewWorkDayCrew, useCrews } from "@crewos/crews";

const CrewWorkDay = ({ crewWorkDay, isReadOnly }) => {
  const [crewsContext] = useCrews();

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );

  return crew ? (
    <CrewWorkDayCrew crew={crew}>
      <CrewWorkDayEquipments
        crewWorkDay={crewWorkDay}
        isReadOnly={isReadOnly}
      />
    </CrewWorkDayCrew>
  ) : null;
};

export default CrewWorkDay;
