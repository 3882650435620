import React, { useEffect, useState } from "react";

import {
  Row,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import { components, sharedHelper, data } from "@crewos/shared";

import { useGetAllExpenseTypes } from "../api/ExpenseTypes.hooks";

import { useUpdateExpense } from "../api/Expenses.hooks";
import {
  useCreateExpenseAttachment,
  useDeleteExpenseAttachment,
} from "../api/ExpenseAttachments.hooks";

const {
  Loader,
  ImagesViewer,
  ImagesViewerModal,
  ConfirmationModal,
  UploadPhotosModal,
} = components;

const { useAuth } = data;

const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const STATUS_PENDING = "pending";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const MODE_PREVIEW = "MODE_PREVIEW";

const PREVIEW_WIDTH = 48;
const PREVIEW_HEIGHT = 48;

const ExpenseModal = ({ readOnly, onClose, onSubmit, expense }) => {
  const [authContext] = useAuth();

  const [imageModal, setImageModal] = useState();
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [localExpense, setLocalExpense] = useState(expense);

  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { mutate: createExpenseAttachment, data: createExpenseAttachmentData } =
    useCreateExpenseAttachment();

  useEffect(() => {
    if (createExpenseAttachmentData) {
      sharedHelper.successToast(`Photos uploaded`);
      onSubmit();
    }
  }, [createExpenseAttachmentData, onSubmit]);

  const { data: deleteExpenseAttachmentData, deleteExpenseAttachment } =
    useDeleteExpenseAttachment();

  useEffect(() => {
    if (deleteExpenseAttachmentData) {
      setConfirmationModal();
      sharedHelper.successToast(`Expense photo deleted`);
      onSubmit();
    }
  }, [deleteExpenseAttachmentData, onSubmit]);

  const {
    isLoading: isLoadingGetExpenseTypes,
    get: getExpenseTypes,
    data: expenseTypesData,
  } = useGetAllExpenseTypes();

  useEffect(() => {
    getExpenseTypes();
  }, [getExpenseTypes]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  const {
    isLoading: isUpdatingExpense,
    update: updateExpense,
    data: updateExpenseData,
  } = useUpdateExpense();

  useEffect(() => {
    if (updateExpenseData) {
      sharedHelper.successToast(`Expense saved`);
      onSubmit();
    }
  }, [updateExpenseData, onSubmit]);

  const onDeleteImage = (attachment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        deleteExpenseAttachment(attachment.id);
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Photo",
      body: `Are you sure you want to delete this photo?`,
      confirmText: "Delete",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (expense.status !== localExpense.status) {
      if (localExpense.status === STATUS_APPROVED) {
        localExpense.approvedAt = moment().toISOString();
        localExpense.approvedBy = authContext.userData.id;
        localExpense.declinedAt = null;
        localExpense.declinedBy = null;
        localExpense.declinedReason = null;
      } else {
        localExpense.approvedAt = null;
        localExpense.approvedBy = null;
        if (localExpense.status === STATUS_DECLINED) {
          localExpense.declinedAt = moment().toISOString();
          localExpense.declinedBy = authContext.userData.id;
        } else {
          localExpense.declinedAt = null;
          localExpense.declinedBy = null;
          localExpense.declinedReason = null;
        }
      }
    }
    await updateExpense(localExpense);
  };

  return confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : showAddPhotoModal ? (
    <UploadPhotosModal
      isMulti={true}
      title="Add Expense's Photos"
      onClose={() => setShowAddPhotoModal(false)}
      includeDescription={true}
      onSubmit={(data) =>
        createExpenseAttachment({
          ...data,
          expenseId: expense.id,
        })
      }
    />
  ) : imageModal ? (
    <ImagesViewerModal
      title="Expense's Photos"
      items={[
        {
          id: imageModal.id,
          title: imageModal.description,
          url: imageModal.attachmentUrl,
        },
      ]}
      onClose={() => setImageModal(false)}
      onDelete={readOnly ? null : onDeleteImage}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {readOnly ? "Expense Detail" : "Edit Expense"}
      </ModalHeader>
      {isUpdatingExpense ? (
        <Loader />
      ) : (
        <Form className="px-1" onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="employee">
                    <span>Employee</span>
                  </Label>
                  <input
                    data-testid="employee"
                    className="form-control-redesign"
                    readOnly={readOnly}
                    required={true}
                    id="employee"
                    name="employee"
                    defaultValue={
                      localExpense.employee
                        ? `${localExpense.employee.firstName} ${localExpense.employee.lastName}`
                        : "-"
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="expenseExpenseTypesSelect">
                    <span>Expense Type</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  {isLoadingGetExpenseTypes ? (
                    <Loader size="sm" />
                  ) : (
                    <select
                      data-testid="expenseExpenseTypesSelect"
                      className="form-control-redesign"
                      disabled={readOnly}
                      name="expenseExpenseTypesSelect"
                      id="expenseExpenseTypesSelect"
                      value={localExpense.expenseTypeId || ""}
                      onChange={(e) =>
                        setLocalExpense({
                          ...localExpense,
                          expenseTypeId: e.target.value,
                        })
                      }
                    >
                      <option value={""}>Select the expense type..</option>
                      {expenseTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="paymentTypeSelect">
                    <span>Payment Method</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <select
                    data-testid="paymentTypeSelect"
                    className="form-control-redesign"
                    disabled={readOnly}
                    required={true}
                    name="paymentTypeSelect"
                    id="paymentTypeSelect"
                    value={localExpense.paymentType || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        paymentType: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select the payment method...</option>
                    <option value={PAYMENT_TYPE_CASH}>Cash</option>
                    <option value={PAYMENT_TYPE_CARD}>Card</option>
                  </select>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="amount">
                    <span>Amount</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <CurrencyInput
                    data-testid="amount"
                    className="form-control-redesign"
                    disabled={readOnly}
                    required={true}
                    id="amount"
                    name="amount"
                    defaultValue={localExpense.amount || ""}
                    prefix="$"
                    decimalLimit={2}
                    max={999999999}
                    allowNegativeValue={false}
                    placeholder="Enter the amount.."
                    onValueChange={(value, name, values) =>
                      setLocalExpense({
                        ...localExpense,
                        amount: parseFloat(value),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">
                    <span>Description</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    data-testid="description"
                    className="form-control-redesign"
                    readOnly={readOnly}
                    required={true}
                    id="description"
                    name="description"
                    rows={4}
                    placeholder="Enter the description .."
                    type="textarea"
                    value={localExpense.description || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        description: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="statusSelect">
                    <span>Status</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <select
                    data-testid="statusSelect"
                    className="form-control-redesign"
                    disabled={readOnly}
                    required={true}
                    name="statusSelect"
                    id="statusSelect"
                    value={localExpense.status || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        status: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select the status</option>
                    <option value={STATUS_PENDING}>Pending</option>
                    <option value={STATUS_APPROVED}>Approved</option>
                    <option value={STATUS_DECLINED}>Declined</option>
                  </select>
                </FormGroup>
                {localExpense.status === STATUS_DECLINED ? (
                  <FormGroup>
                    <Label htmlFor="declinedReason">
                      <span>Declined Reason</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <input
                      data-testid="declinedReason"
                      className="form-control-redesign"
                      readOnly={readOnly}
                      required={true}
                      id="declinedReason"
                      rows={4}
                      placeholder="Enter the reason .."
                      type="textarea"
                      maxLength="255"
                      value={localExpense.declinedReason || ""}
                      onChange={(e) =>
                        setLocalExpense({
                          ...localExpense,
                          declinedReason: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                ) : null}
                <FormGroup className="mb-0">
                  <Label>
                    <span>Photos</span>
                  </Label>
                  <div className="d-flex mx-n1">
                    {readOnly ? null : (
                      <Col
                        style={{
                          maxWidth: PREVIEW_WIDTH,
                          maxHeight: PREVIEW_HEIGHT,
                          minWidth: PREVIEW_WIDTH,
                          minHeight: PREVIEW_HEIGHT,
                        }}
                        className="mb-1 mt-1 cursor-pointer px-0 mx-1 d-flex align-items-center justify-content-center"
                        onClick={() => setShowAddPhotoModal(true)}
                        data-testid="add-photo-button"
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          size="lg"
                          className="text-primary"
                        />
                      </Col>
                    )}
                    {expense.expenseAttachments.length ? (
                      expense.expenseAttachments.map((attachment, i) => (
                        <Col
                          key={attachment.id}
                          style={{
                            maxWidth: PREVIEW_WIDTH,
                            maxHeight: PREVIEW_HEIGHT,
                            minWidth: PREVIEW_WIDTH,
                            minHeight: PREVIEW_HEIGHT,
                          }}
                          className="my-1 cursor-pointer px-0 mx-1 border-radius-default overflow-hidden"
                          onClick={() => setImageModal(attachment)}
                        >
                          <ImagesViewer
                            mode={MODE_PREVIEW}
                            height={PREVIEW_WIDTH}
                            width={PREVIEW_HEIGHT}
                            showActions={false}
                            showCaption={false}
                            items={[
                              {
                                url: attachment.attachmentUrl,
                                title: attachment.description,
                              },
                            ]}
                          />
                        </Col>
                      ))
                    ) : readOnly ? (
                      <small className="col-12 text-muted">No photos</small>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex flex-nowrap justify-content-center">
            <Button
              color="secondary"
              onClick={onClose}
              className="col-6 text-dark"
            >
              Cancel
            </Button>
            <Button color="primary" type="submit" className="col-6">
              Save
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default ExpenseModal;
