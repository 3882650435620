import React, { useEffect, useState } from "react";

import {
  Form,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
} from "reactstrap";

import { useUpdateEmployee, useCreateEmployee } from "../api/Employees.hooks";

import { useGetEmployeeRoles } from "../api/EmployeeRoles.hooks";

import { components, sharedHelper } from "@crewos/shared";

const { Loader } = components;

const MAX_PAGE_SIZE = Number.MAX_SAFE_INTEGER;

const EmployeeModal = ({
  employee = { isActive: true },
  onSubmit,
  onClose,
}) => {
  const [employeeData, setEmployeeData] = useState({ ...employee });

  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  const {
    isLoading: isUpdatingEmployee,
    update: updateEmployee,
    data: updateEmployeeData,
  } = useUpdateEmployee();

  const {
    isLoading: isLoadingCreateEmployee,
    mutate: createEmployee,
    data: createEmployeeData,
  } = useCreateEmployee();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (employee.id) {
      await updateEmployee({
        ...employeeData,
      });
    } else {
      await createEmployee({
        ...employeeData,
      });
    }
  };

  useEffect(() => {
    if (updateEmployeeData) {
      sharedHelper.successToast(`Employee saved`);
      onSubmit();
    }
  }, [updateEmployeeData, onSubmit]);

  useEffect(() => {
    if (createEmployeeData) {
      sharedHelper.successToast(`Employee created`);
      onSubmit();
    }
  }, [createEmployeeData, onSubmit]);

  useEffect(() => {
    getEmployeeRoles({
      isActive: true,
      pageSize: MAX_PAGE_SIZE,
      sortBy: "name",
    });
  }, [getEmployeeRoles]);

  return (
    <Modal isOpen={true} size="sm">
      <ModalHeader className="d-flex justify-content-between" toggle={onClose}>
        {employee.id ? "Edit" : "Create"} Employee
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Row>
            {isUpdatingEmployee || isLoadingCreateEmployee ? (
              <Loader size="sm" />
            ) : (
              <Col>
                <FormGroup>
                  <Label>
                    <span>First Name</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="firstName"
                    placeholder="Enter the first name.."
                    value={employeeData.firstName || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Last Name</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="lastName"
                    placeholder="Enter the last name.."
                    value={employeeData.lastName || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        lastName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Email</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="email"
                    placeholder="Enter the email.."
                    value={employeeData.email || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        email: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Phone</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="phone"
                    placeholder="Enter the phone.."
                    value={employeeData.phone || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        phone: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                {isLoadingEmployeeRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label>
                      <span>Role</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      type="select"
                      name="roleSelect"
                      id="roleSelect"
                      onChange={(e) =>
                        setEmployeeData({
                          ...employeeData,
                          employeeRoleId: e.target.value,
                        })
                      }
                      value={employeeData.employeeRoleId}
                      required
                    >
                      <option value={""}>Select a role..</option>
                      {employeeRoles?.data.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                      {employeeData.role && !employeeData.role.isActive ? (
                        <option value={employeeData.employeeRoleId}>
                          {employeeData.role.name}
                        </option>
                      ) : null}
                    </select>
                  </FormGroup>
                )}
                {employee.id ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Status</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <select
                      className="form-control-redesign"
                      type="select"
                      name="statusSelect"
                      id="statusSelect"
                      onChange={(e) => {
                        setEmployeeData({
                          ...employeeData,
                          isActive: e.target.value === "true",
                        });
                      }}
                      value={employeeData.isActive ? "true" : "false"}
                      required
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </FormGroup>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EmployeeModal;
