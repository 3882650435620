import React, { useState, useEffect } from "react";

import { Button, Row, Card, CardBody } from "reactstrap";
import Expense from "./Expense";
import { useGetExpenses } from "../api/Expenses.hooks";

import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { components, sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const { Loader } = components;

const CrewWorkDayExpenses = ({ crewWorkDay, readOnly }) => {
  const [refresh, setRefresh] = useState();
  const [expenses, setExpenses] = useState([]);

  const {
    data: getExpensesData,
    isLoading: isLoadingGetExpenses,
    get: getExpenses,
  } = useGetExpenses();

  useEffect(() => {
    getExpenses({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getExpenses, refresh, crewWorkDay]);

  useEffect(() => {
    if (getExpensesData) {
      setExpenses(getExpensesData);
    }
  }, [getExpensesData, setExpenses]);

  return (
    <Card className="w-100 mb-3 px-0">
      <CardBody>
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Expenses</h3>
          <div className="d-flex">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
          </div>
        </div>
        {isLoadingGetExpenses ? (
          <Loader size="sm" className="my-3" />
        ) : expenses.length ? (
          <Row className="mt-3 d-flex justify-content-start align-items-start">
            {expenses.map((expense) => (
              <div key={expense.id} className="col-3 my-1">
                <Expense
                  expense={expense}
                  readOnly={readOnly}
                  onRefresh={() => setRefresh((prev) => !prev)}
                />
              </div>
            ))}
          </Row>
        ) : (
          <div className="p-2 small text-muted text-center">No expenses</div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayExpensesWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "expenses")
  ) {
    return null;
  }

  return <CrewWorkDayExpenses {...props} />;
};

export default CrewWorkDayExpensesWrapper;
