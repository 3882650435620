import React, { useState } from "react";

import { Badge, Table } from "reactstrap";

import { sharedHelper, data, components } from "@crewos/shared";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { InformationModal } = components;

const { useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const CREWS_PACKAGE = "crews";

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const EmployeeCrewRow = ({ employeeCrew, perDiem }) => {
  const [authContext] = useAuth();

  const [informationModal, setInformationModal] = useState();

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const onPerDiemInfo = (perDiem) => {
    setInformationModal({
      title: "Per Diem Details",
      rawBody: true,
      size: "xl",
      onClose: () => setInformationModal(),
      body: (
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Rule</th>
              <th>Precendence</th>
              <th>Condition</th>
              <th>Amount</th>
              <th>Dates</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{perDiem.name}</td>
              <td>{perDiem.precedence}</td>
              <td>
                <code>{perDiem.condition}</code>
              </td>
              <td>{sharedHelper.formatCurrency(perDiem.amount)}</td>
              <td>
                {perDiem.startDate
                  ? `${perDiem.startDate} to ${perDiem.endDate}`
                  : "Open Dates"}
              </td>
              <td>{perDiem.role}</td>
            </tr>
          </tbody>
        </Table>
      ),
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between p-3 border-radius-default box-shadow-lg my-2">
      <div className="d-flex align-items-center col-6">
        <span>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</span>
        <div className="text-muted d-flex align-items-center ms-2">
          {!directAssignationEnabled && employeeCrew.isLead ? (
            <Badge color="primary" className="me-2">
              Crew Lead
            </Badge>
          ) : null}
          <Badge color="primarylight" className="text-primary">
            {employeeCrew.role.name}
          </Badge>
        </div>
      </div>
      {perDiem ? (
        <div className="d-flex align-items-center">
          <span>{sharedHelper.formatCurrency(perDiem.amount)}</span>
          {isSuperAdmin ? (
            <FontAwesomeIcon
              onClick={() => onPerDiemInfo(perDiem)}
              icon={faInfoCircle}
              className="ms-2 text-muted cursor-pointer"
              data-testid="info-circle-icon"
            />
          ) : null}
        </div>
      ) : (
        <div className="text-muted col-6 text-end small">No per diem</div>
      )}
      {informationModal ? <InformationModal {...informationModal} /> : null}
    </div>
  );
};

export default EmployeeCrewRow;
