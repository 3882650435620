import React, { useEffect, useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";

import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { crewHelper, useCrews } from "@crewos/crews";
import { useCreateExpense } from "../api/Expenses.hooks";

import { components, data, sharedHelper } from "@crewos/shared";
import CrewMembers from "./CrewMembers";

const { Loader } = components;
const { useWorkOrderDetails, useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const AddExpenseModal = ({ onClose, onSubmit }) => {
  const [authContext] = useAuth();
  const [crewsContext] = useCrews();
  const [workOrderDetails] = useWorkOrderDetails();

  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [paymentTypeToAdd, setPaymentTypeToAdd] = useState({});
  const [descriptionToAdd, setDescriptionToAdd] = useState({});
  const [expenseTypeToAdd, setExpenseTypeToAdd] = useState({});
  const [amountToAdd, setAmountToAdd] = useState({});

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const { id: workOrderId } = workOrderDetails.workOrder;

  const {
    isLoading: isLoadingCreateExpense,
    mutate: createExpense,
    data: createExpenseData,
  } = useCreateExpense();

  useEffect(() => {
    if (createExpenseData) {
      sharedHelper.successToast(`Expenses added`);
      onSubmit();
    }
  }, [createExpenseData, onSubmit]);

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(employeeCrewPastDatesEnabled, crew, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const doSubmit = async (event) => {
    event.preventDefault();
    const expenses = Object.keys(amountToAdd).map((employeeId) => ({
      employeeId,
      crewId: crew.id,
      amount: amountToAdd[employeeId] || 0,
      description: descriptionToAdd[employeeId],
      date: moment(selectedDate).format("YYYY-MM-DD"),
      paymentType: paymentTypeToAdd[employeeId],
      expenseTypeId: expenseTypeToAdd[employeeId],
      workOrderId,
    }));

    if (!expenses.length) {
      sharedHelper.warningToast("Add expenses to at least one employee");
    }
    await createExpense({ expenses });
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
  };

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">Add Expense</div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateExpense ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={onSelectedDate}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              <FormGroup className="d-flex flex-column align-items-center col-12 px-0">
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>{directAssignationEnabled ? "Employee" : "Crew"}</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                {crewsWithLeadForDate.length ? (
                  <select
                    className="form-control-redesign px-0"
                    required={true}
                    type="select"
                    name="crewSelect"
                    id="crewSelect"
                    data-testid={`${directAssignationEnabled ? "employee" : "crew"
                      }-select`}
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crew = crewsContext.crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>
                      Select a
                      {directAssignationEnabled ? "n Employee" : " Crew"}
                    </option>
                    {crewsWithLeadForDate.map((crew) => {
                      const lead = crewHelper.getCrewLead(
                        employeeCrewPastDatesEnabled,
                        crew
                      );
                      return (
                        <option key={crew.id} value={crew.id}>
                          {`${lead.employee.firstName} ${lead.employee.lastName}`}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div className="text-muted small">
                    No crews for selected date
                  </div>
                )}
              </FormGroup>
            </>
          )}
          {crew &&
            selectedDate &&
            crewsWithLeadForDate.find((c) => c.id === crew.id) ? (
            <CrewMembers
              crew={crew}
              selectedDate={selectedDate}
              paymentTypeToAdd={paymentTypeToAdd}
              setPaymentTypeToAdd={setPaymentTypeToAdd}
              descriptionToAdd={descriptionToAdd}
              setDescriptionToAdd={setDescriptionToAdd}
              expenseTypeToAdd={expenseTypeToAdd}
              setExpenseTypeToAdd={setExpenseTypeToAdd}
              employeeEnabled={employeeEnabled}
              setEmployeeEnabled={setEmployeeEnabled}
              amountToAdd={amountToAdd}
              setAmountToAdd={setAmountToAdd}
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateExpense ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddExpenseModal;
