import React, { useEffect } from "react";
import { useParams } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { sharedHelper } from "@crewos/shared";

import { data } from "@crewos/shared";

import { useGetWorkOrders } from "../api/WorkOrders.hooks";
import { components } from "@crewos/shared";

import WorkOrderStatus from "../components/WorkOrderStatus";

import WorkOrderDetailsTabs from "../components/workOrderDetails/WorkOrderDetailsTabs";
import { CrewsData } from "@crewos/crews";

const {
  useWorkOrderDetails,
  WORK_ORDER_DETAILS_ACTIONS,
  WorkOrderDetailsProvider,
  useAuth,
} = data;

const { Loader } = components;

const WorkOrderDetailsTabsWrapper = () => {
  const [authContext] = useAuth();
  const crewsEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "crews"
  );

  return crewsEnabled ? (
    <CrewsData>
      <WorkOrderDetailsTabs />
    </CrewsData>
  ) : (
    <WorkOrderDetailsTabs />
  );
};

const WorkOrderDetails = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const navigate = useNavigate();

  const { workOrderId } = useParams();

  const {
    data: workOrder,
    error: workOrderErr,
    isLoading: isLoadingWorkOrder,
    get: getWorkOrder,
  } = useGetWorkOrders();

  useEffect(() => {
    getWorkOrder({ id: workOrderId });
  }, [getWorkOrder, workOrderDetails.refresh, workOrderId]);

  useEffect(() => {
    if (workOrder) {
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.GET_WORK_ORDER_SUCCESS,
        payload: { workOrder },
      });
    }
  }, [workOrder, setWorkOrderDetails]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/workorders");
    }
  };

  useEffect(() => {
    if (workOrderErr) {
      navigate("/workorders");
    }
  }, [workOrderErr, navigate]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Row>
        {!workOrderDetails.workOrder || isLoadingWorkOrder ? (
          <Loader />
        ) : (
          <Col className="flex-grow-1 d-flex flex-column">
            <Card className="box-shadow-none">
              <CardHeader className="px-0">
                <div className="d-flex col-12 px-0 px-2 text-dark">
                  <div
                    className="d-flex flex-column justify-content-center cursor-pointer"
                    onClick={goBack}
                    data-testid="back-button"
                  >
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size="sm"
                      className="me-2 text-dark border rounded-circle back-arrow bg-white"
                    />
                  </div>
                  <h2 className="mb-0">
                    {workOrderDetails.workOrder.customer?.customerName ||
                      "No customer name provided"}
                  </h2>
                </div>
              </CardHeader>
              <CardBody className="pb-1">
                <div className="d-flex align-items-center justify-content-start mb-2">
                  <div className="bg-primary text-white py-1 px-2 rounded small">
                    {workOrderDetails.workOrder.workOrderStatus.name}
                  </div>
                  <span className="mx-2">·</span>
                  <div>
                    {`${sharedHelper.formatDate(
                      workOrderDetails.workOrder.startDate,
                      "MM/DD/YYYY"
                    )} to ${sharedHelper.formatDate(
                      workOrderDetails.workOrder.endDate,
                      "MM/DD/YYYY"
                    )}`}
                  </div>
                  {workOrderDetails.workOrder.customerLocation ? (
                    <>
                      <span className="mx-2">·</span>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">Address:</span>
                        <span className="ms-1">
                          {workOrderDetails.workOrder.customerLocation
                            .shipToAddress || "-"}
                        </span>
                      </div>
                    </>
                  ) : null}
                  {workOrderDetails.workOrder.customerLocation ? (
                    <>
                      <span className="mx-2">·</span>
                      <div className="d-flex align-items-center">
                        <span className="text-muted">Phone:</span>
                        <span className="ms-1">
                          {workOrderDetails.workOrder.customerLocation.phone ||
                            "-"}
                        </span>
                      </div>
                    </>
                  ) : null}
                  <span className="mx-2">·</span>
                  <div className="d-flex align-items-center">
                    <span>{workOrderDetails.workOrder.workOrderNumber}</span>
                  </div>
                </div>
                <WorkOrderStatus />
              </CardBody>
            </Card>
            <WorkOrderDetailsTabsWrapper />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const WorkOrderDetailsWrapper = (props) => (
  <WorkOrderDetailsProvider>
    <WorkOrderDetails {...props} />
  </WorkOrderDetailsProvider>
);

export default WorkOrderDetailsWrapper;
