import React, { useEffect, useState } from "react";

import { Card, CardBody } from "reactstrap";

import { components, data } from "@crewos/shared";

import { sharedHelper } from "@crewos/shared";

import { useGetPerDiems } from "../api/PerDiems.hooks";

import EmployeeCrewRow from "./EmployeeCrewRow";

import { crewHelper } from "@crewos/crews";

const { useAuth } = data;

const { Loader } = components;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const CrewWorkDayPerDiems = ({ crewWorkDay, crew }) => {
  const [authContext] = useAuth();
  const [perDiems, setPerDiems] = useState([]);

  const {
    data: getPerDiemsData,
    isLoading: isLoadingGetPerDiems,
    get: getPerDiems,
  } = useGetPerDiems();

  useEffect(() => {
    getPerDiems({
      crewWorkDayId: crewWorkDay.id,
    });
  }, [getPerDiems, crewWorkDay]);

  useEffect(() => {
    if (getPerDiemsData) {
      setPerDiems(getPerDiemsData);
    }
  }, [getPerDiemsData, setPerDiems]);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const activeCrew = crew.employeeCrews.filter((ec) =>
    crewHelper.isEmployeeCrewActiveOnDate(
      employeeCrewPastDatesEnabled,
      ec,
      crewWorkDay.date
    )
  );

  return isLoadingGetPerDiems ? (
    <Loader size="sm" className="my-3" />
  ) : (
    <Card className="w-100 mb-3 px-0">
      <CardBody className="pb-2">
        <h3>Per Diem</h3>
        {!directAssignationEnabled ? (
          <div
            key={crewWorkDay.id}
            className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default"
          >
            <div className="col-6">CREW MEMBER</div>
            <div className="col-6 text-end">PER DIEM</div>
          </div>
        ) : null}
        {activeCrew.length ? (
          !Object.values(perDiems).length ? (
            <div className="p-2 small text-muted text-center">
              No per diem generated
            </div>
          ) : (
            activeCrew
              .sort(sharedHelper.sortEmployeeCrew)
              .map((employeeCrew) => {
                const employeePerDiem = perDiems[employeeCrew.employeeId];
                return (
                  <EmployeeCrewRow
                    key={employeeCrew.id}
                    employeeCrew={employeeCrew}
                    perDiem={employeePerDiem}
                  />
                );
              })
          )
        ) : (
          <div className="p-2 small text-muted text-center">Empty Crew</div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayPerDiemsWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "perdiems")
  ) {
    return null;
  }

  return <CrewWorkDayPerDiems {...props} />;
};

export default CrewWorkDayPerDiemsWrapper;
