import React from "react";

import { CrewWorkDayExpenses } from "@crewos/expenses";
import { CrewWorkDayWorkTimes } from "@crewos/worktimes";
import { CrewWorkDayTravelTimes } from "@crewos/traveltimes";

import { useCrews } from "../providers/crewsProvider";

import { CrewWorkDayNotes } from "@crewos/notes";
import { CrewWorkDaysPhotos } from "@crewos/attachments";
import CrewWorkDayCrew from "./CrewWorkDayCrew";

const WorkPerformedWorkDay = ({ crewWorkDay }) => {
  const [crewsContext] = useCrews();

  const crew = crewsContext.crews.find(
    (crew) => crew.id === crewWorkDay.crewId
  );

  return crew ? (
    <CrewWorkDayCrew crew={crew}>
      <CrewWorkDayWorkTimes crewWorkDay={crewWorkDay} crew={crew} />
      <CrewWorkDayTravelTimes crewWorkDay={crewWorkDay} crew={crew} />
      <CrewWorkDayExpenses crewWorkDay={crewWorkDay} readOnly={true} />
      <CrewWorkDayNotes crewWorkDay={crewWorkDay} />
      <CrewWorkDaysPhotos crewWorkDay={crewWorkDay} />
    </CrewWorkDayCrew>
  ) : null;
};

export default WorkPerformedWorkDay;
