import React, { useEffect, useState } from "react";

import { useCrews } from "../providers/crewsProvider";

import { data, sharedHelper, components } from "@crewos/shared";

import { useGetWorkOrderWorkPerformed } from "@crewos/workorders";

import { Card, CardBody, ListGroupItem } from "reactstrap";

import moment from "moment";
import { crewHelper } from "../helpers/crewHelper";

const { useWorkOrderDetails, useAuth } = data;

const { Loader, InformationModal } = components;

const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const CREWS_PACKAGE = "crews";
const TRAVEL_TIME_PACKAGE = "traveltimes";
const WORK_TIME_PACKAGE = "worktimes";
const EXPENSES_PACKAGE = "expenses";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const WorkPerformedCards = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewsContext] = useCrews();
  const [authContext] = useAuth();

  const [informationModal, setInformationModal] = useState();

  const {
    data,
    isLoading,
    get: getWorkPerformed,
  } = useGetWorkOrderWorkPerformed(workOrderDetails.workOrder.id);

  useEffect(() => {
    if (workOrderDetails.workOrder.id) {
      getWorkPerformed();
    }
  }, [getWorkPerformed, workOrderDetails.workOrder]);

  const isSingleCrewEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    SINGLE_CREW
  );

  const travelTimePackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    TRAVEL_TIME_PACKAGE
  );

  const workTimePackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    WORK_TIME_PACKAGE
  );

  const expensesPackageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    EXPENSES_PACKAGE
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const crewMembers = new Set(
    crewsContext.crews.flatMap((crew) =>
      crew.employeeCrews
        .filter((ec) =>
          crewHelper.isEmployeeCrewActiveOnDate(
            employeeCrewPastDatesEnabled,
            ec
          )
        )
        .map((ec) => ec.employeeId)
    )
  ).size;

  let workOrderLength = moment(workOrderDetails.workOrder.endDate).diff(
    moment(workOrderDetails.workOrder.startDate),
    "days"
  );
  let workOrderLengthUnits = "day";
  if (workOrderLength > 365) {
    workOrderLength = workOrderLength / 365;
    workOrderLengthUnits = "year";
  } else if (workOrderLength > 30) {
    workOrderLength = workOrderLength / 30;
    workOrderLengthUnits = "month";
  } else if (workOrderLength > 7) {
    workOrderLength = workOrderLength / 7;
    workOrderLengthUnits = "week";
  }

  const workedDays = data?.crewWorkDays.map((cwd) => cwd.date) || [];

  const onWorkedDays = () =>
    setInformationModal({
      title: "Worked Days",
      rawBody: true,
      size: "sm",
      onClose: () => setInformationModal(),
      footerClass: "mt-n3",
      body: workedDays
        .sort((x, y) => (moment(x).isBefore(moment(y), "date") ? 1 : -1))
        .map((date) => (
          <ListGroupItem
            className="mb-2 border-radius-default text-center py-1 bg-graylight border-0"
            size="sm"
          >
            <span>{date}</span>
          </ListGroupItem>
        )),
    });

  const totalExpenses = expensesPackageEnabled
    ? data?.crewWorkDays.reduce((p, c) => {
        const dateExpenses = c.expenses.reduce((p, c) => p + c.amount, 0);
        return p + dateExpenses;
      }, 0) || 0
    : 0;

  const workedTime = workTimePackageEnabled
    ? data?.crewWorkDays.reduce((p, c) => {
        const dateWorkTimes = c.workTimes.reduce((p, c) => {
          const exceptionHours = sharedHelper.calculateExceptions(c);
          return p + c.hours + exceptionHours;
        }, 0);
        return p + dateWorkTimes;
      }, 0) || 0
    : 0;

  const traveledTime = travelTimePackageEnabled
    ? data?.crewWorkDays.reduce((p, c) => {
        const dateTravelTimes = c.travelTimes.reduce((p, c) => p + c.hours, 0);
        return p + dateTravelTimes;
      }, 0) || 0
    : 0;

  const traveledMileage = travelTimePackageEnabled
    ? data?.crewWorkDays.reduce((p, c) => {
        const dateTravelMileage = c.travelTimes.reduce(
          (p, c) => p + c.mileage,
          0
        );
        return p + dateTravelMileage;
      }, 0) || 0
    : 0;

  return (
    <div className="d-flex justify-content-start p-3 work-performed-cards overflow-auto">
      {!isSingleCrewEnabled ? (
        <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
          <CardBody className="pb-3">
            <h1 className="mb-0 text-center">{crewsContext.crews.length}</h1>
            <div className="mt-1 text-center small text-truncate">
              {directAssignationEnabled ? "assignment" : "crew"}
              {crewsContext.crews.length !== 1 ? "s" : ""}
            </div>
          </CardBody>
        </Card>
      ) : null}
      {!directAssignationEnabled ? (
        <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
          <CardBody className="pb-3">
            <h1 className="mb-0 text-center" data-testid="crew-members">
              {crewMembers}
            </h1>
            <div className="mt-1 text-center small text-truncate">
              crew member{crewMembers !== 1 ? "s" : ""}
            </div>
          </CardBody>
        </Card>
      ) : null}
      <Card
        className="flex-grow-1 flex-shrink-0 mb-0 me-4 cursor-pointer"
        onClick={() => onWorkedDays(workedDays)}
      >
        <CardBody className="pb-3">
          <h1 className="mb-0 text-center">{workedDays.length}</h1>
          <div className="mt-1 text-center small text-truncate">
            worked day{workedDays.length !== 1 ? "s" : ""}
          </div>
        </CardBody>
      </Card>
      <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
        <CardBody className="pb-3">
          <h1 className="mb-0 text-center">{Math.floor(workOrderLength)}</h1>
          <div className="mt-1 text-center small text-truncate">
            {workOrderLengthUnits}
            {Math.floor(workOrderLength) !== 1 ? "s" : ""} long
          </div>
        </CardBody>
      </Card>
      {workTimePackageEnabled ? (
        <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
          {isLoading || !data ? (
            <Loader size="sm" className="h-100" />
          ) : (
            <CardBody className="pb-3">
              <h1 className="mb-0 text-center">{workedTime.toFixed(2)}</h1>
              <div className="mt-1 text-center small text-truncate">
                worked hour
                {workedTime !== 1 ? "s" : ""}
              </div>
            </CardBody>
          )}
        </Card>
      ) : null}
      {travelTimePackageEnabled ? (
        <>
          <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
            {isLoading || !data ? (
              <Loader size="sm" className="h-100" />
            ) : (
              <CardBody className="pb-3">
                <h1 className="mb-0 text-center">{traveledTime.toFixed(2)}</h1>
                <div className="mt-1 text-center small text-truncate">
                  traveled hour
                  {traveledTime !== 1 ? "s" : ""}
                </div>
              </CardBody>
            )}
          </Card>
          <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
            {isLoading || !data ? (
              <Loader size="sm" className="h-100" />
            ) : (
              <CardBody className="pb-3">
                <h1 className="mb-0 text-center">
                  {traveledMileage.toFixed(2)}
                </h1>
                <div className="mt-1 text-center small text-truncate">
                  traveled mile
                  {traveledMileage !== 1 ? "s" : ""}
                </div>
              </CardBody>
            )}
          </Card>
        </>
      ) : null}
      {expensesPackageEnabled ? (
        <Card className="flex-grow-1 flex-shrink-0 mb-0 me-4">
          <CardBody className="pb-3">
            <h1 className="mb-0 text-center currency">
              {sharedHelper.formatCurrency(totalExpenses)}
            </h1>
            <div className="mt-1 text-center small text-truncate">
              total expenses
            </div>
          </CardBody>
        </Card>
      ) : null}
      {informationModal ? <InformationModal {...informationModal} /> : null}
    </div>
  );
};

export default WorkPerformedCards;
