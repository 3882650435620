import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Label from "reactstrap/lib/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt, faHatHard } from "@fortawesome/free-solid-svg-icons";
import {
  useGetEmployees,
  useGetEmployeeRoles,
  isCrewLeadRole,
} from "@crewos/employees";
import { sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const MODE_LEAD = 1;
const MODE_DEFAULT = 2;
const PAGE_SIZE = 30;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const CREWS_PACKAGE = "crews";

const EmployeeSelector = ({
  selectedEmployees,
  setSelectedEmployees,
  selectedEmployee,
  mode,
}) => {
  const [authContext] = useAuth();
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employee, setEmployee] = useState(null);
  const [roleSearch, setRoleSearch] = useState("");
  const [role, setRole] = useState(null);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();
  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  useEffect(() => {
    if (selectedEmployee) {
      setEmployee({
        label: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
        value: selectedEmployee.id,
      });
      if (selectedEmployee.role) {
        setRole({
          label: (
            <span
              className={selectedEmployee.role.isActive ? "" : "text-muted"}
            >
              {`${selectedEmployee.role.name} ${
                !selectedEmployee.role.isActive ? "(Inactive)" : ""
              }`}
            </span>
          ),
          value: selectedEmployee.role.id,
        });
      } else {
        setRole(null);
      }
    } else {
      setEmployee(null);
      setRole(null);
    }
  }, [selectedEmployee]);

  useEffect(() => {
    getEmployees({ search: employeeSearch, pageSize: PAGE_SIZE });
  }, [getEmployees, employeeSearch]);

  useEffect(() => {
    getEmployeeRoles({ search: roleSearch, pageSize: PAGE_SIZE });
  }, [getEmployeeRoles, roleSearch]);

  const onSelectEmployee = (employee) => {
    const index = selectedEmployee
      ? selectedEmployees.findIndex((e) => e.id === selectedEmployee.id)
      : -1;
    if (employee && index < 0) {
      selectedEmployees.push(employee);
    } else if (employee) {
      selectedEmployees.splice(index, 1, employee);
    } else if (index >= 0) {
      selectedEmployees.splice(index, 1);
    }
    setSelectedEmployees([...selectedEmployees].filter(Boolean));
  };

  const onEmployeeChange = (selected) => {
    if (selected) {
      const employee = employees.data.find(
        (employee) => employee.id === selected.value
      );
      employee.isLead = mode === MODE_LEAD;
      onSelectEmployee(employee);
    } else {
      onSelectEmployee();
    }
  };

  const onRoleChange = (selected) => {
    const employee = { ...selectedEmployee };
    if (selected) {
      const employeeRole = employeeRoles.data.find(
        (role) => role.id === selected.value
      );
      employee.role = employeeRole;
      onSelectEmployee(employee);
    } else {
      employee.role = null;
      onSelectEmployee(employee);
    }
  };

  const employeeOptions = (employees?.data || [])
    .filter(
      (option) =>
        !selectedEmployees.find((employee) => employee.id === option.id)
    )
    .map((option) => ({
      label: `${option.firstName} ${option.lastName}`,
      value: option.id,
    }));

  const employeeRoleOptions = (employeeRoles?.data || [])
    .filter((role) => (mode === MODE_LEAD ? isCrewLeadRole(role) : true))
    .map((role) => ({
      label: role.name,
      value: role.id,
    }));

  return (
    <div className={`col-${directAssignationEnabled ? "12" : "6"} my-2 px-2`}>
      <FormGroup className="d-flex flex-column p-3 border border-radius-default">
        {!directAssignationEnabled ? (
          <Label className="d-flex align-items-center justify-content-between bg-graylight p-2 border-radius-md">
            <div className="d-flex align-items-center fw-bold">
              {mode === MODE_LEAD ? "Lead" : mode === MODE_DEFAULT ? "" : "New"}{" "}
              Employee
            </div>
            <FontAwesomeIcon
              icon={mode === MODE_LEAD ? faShieldAlt : faHatHard}
              className="ms-2 text-primary"
              style={{ paddingBottom: "1px" }}
            />
          </Label>
        ) : null}
        <Select
          entity="employee"
          isMulti={false}
          placeholder="Search for an employee.."
          noOptionsMessage={() => "No employees found"}
          styles={style}
          classNamePrefix="react-select"
          className="text-start bg-white px-0 react-select"
          options={employeeOptions}
          closeMenuOnSelect={true}
          components={animatedComponents}
          isSearchable
          isClearable
          inputValue={employeeSearch}
          value={employee}
          onInputChange={setEmployeeSearch}
          isLoading={isLoadingEmployees}
          onChange={onEmployeeChange}
        />
        {employee ? (
          <Select
            entity="employee-role"
            isMulti={false}
            placeholder="Search for a role.."
            noOptionsMessage={() => "No roles found"}
            styles={style}
            classNamePrefix="react-select"
            className="text-start bg-white px-0 mt-3 react-select"
            options={employeeRoleOptions}
            closeMenuOnSelect={true}
            components={animatedComponents}
            isSearchable
            isClearable
            inputValue={roleSearch}
            value={role}
            onInputChange={setRoleSearch}
            isLoading={isLoadingEmployeeRoles}
            onChange={onRoleChange}
          />
        ) : null}
      </FormGroup>
    </div>
  );
};

export default EmployeeSelector;
