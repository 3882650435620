import TemplatesWorkOrder from "./TemplatesWorkOrder";
import { useGetTemplateWorkOrder } from "../api/Inspections.hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import InspectionsWrapper from "./Inspections";
import { components } from "@crewos/shared";

const { Loader } = components;

const CrewWorkDay = ({ crewWorkDay, workOrder, needsUpdate, search }) => {
  const {
    data: templateWOData,
    isLoading: isLoadingTemplateWO,
    get: getTemplateWO,
  } = useGetTemplateWorkOrder();

  const handleGetTemplateWO = useCallback(() => {
    const payload = {
      workOrderId: workOrder.id,
      crewWorkDayId: crewWorkDay.id,
      search,
      page: 0,
      pageSize: 100,
    };
    getTemplateWO(payload);
  }, [workOrder.id, crewWorkDay.id, getTemplateWO, search]);

  useEffect(() => {
    handleGetTemplateWO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsUpdate, search]);

  const templateWOFiltered = useMemo(() => {
    return templateWOData && templateWOData.data ? templateWOData.data : [];
  }, [templateWOData]);

  if (isLoadingTemplateWO) {
    return <Loader size="sm" />;
  }

  return templateWOFiltered.map((templateWO) => (
    <TemplatesWorkOrder key={templateWO.id} templateWorkOrder={templateWO}>
      <InspectionsWrapper
        inspections={templateWO.inspections}
        onInspectionDelete={handleGetTemplateWO}
      />
    </TemplatesWorkOrder>
  ));
};

export default CrewWorkDay;
