import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Hamburger from "../components/Hamburger";
import { Outlet } from "react-router-dom";

const Admin = () => {
  return (
    <React.Fragment>
      <div className="d-flex min-width-lg h-100 w-100">
        <Sidebar />
        <div className="d-flex flex-column justify-content-between flex-grow-1 admin-layout">
          <Hamburger />
          <div className="p-2 flex-grow-1 d-flex flex-column">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admin;
