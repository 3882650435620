import React, { useEffect, useState } from "react";

import { Col, Row, FormGroup, Label } from "reactstrap";

import { useGetCustomers } from "@crewos/customers";

import { components, data } from "@crewos/shared";

import Select from "react-select";

import makeAnimated from "react-select/animated";

const { useAuth } = data;

const animatedComponents = makeAnimated();

const { DatesSelector } = components;

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const PAGE_SIZE_CUSTOMERS = 30;

const WorkOrderForm = ({ workOrderData, setWorkOrderData }) => {
  const [authContext] = useAuth();
  const [customerSearch, setCustomerSearch] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [customerLocationSearch, setCustomerLocationSearch] = useState();
  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState();
  const [customerLocationsOptions, setCustomerLocationsOptions] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState();

  const {
    data: customers,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  useEffect(() => {
    if (
      authContext.userData.workOrderStatus &&
      workOrderData?.workOrderStatusId
    ) {
      const selectedStatus = authContext.userData.workOrderStatus?.find(
        (s) => s.id === workOrderData?.workOrderStatusId
      );
      if (selectedStatus) {
        setSelectedStatus({
          value: selectedStatus?.id,
          label: selectedStatus?.name,
        });
      } else {
        setSelectedStatus();
      }
    }
  }, [authContext.userData.workOrderStatus, workOrderData]);

  useEffect(() => {
    if (customers) {
      const selectedCustomer = customers.data.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        setSelectedCustomer({
          value: selectedCustomer?.id,
          label: selectedCustomer?.customerName,
        });
      } else {
        setSelectedCustomer();
      }
    }
  }, [customers, workOrderData]);

  useEffect(() => {
    if (customers) {
      const selectedCustomer = customers.data.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        const selectedLocation = selectedCustomer.locations.find(
          (s) => s.id === workOrderData?.customerLocationId
        );
        const customerLocationsOptions =
          selectedCustomer?.locations.map((option) => ({
            label: option.shipToAddress,
            value: option.id,
          })) || [];
        setCustomerLocationsOptions(customerLocationsOptions);
        if (selectedLocation) {
          setSelectedCustomerLocation({
            value: selectedLocation?.id,
            label: selectedLocation?.shipToAddress,
          });
        } else {
          setSelectedCustomerLocation();
        }
      } else {
        setSelectedCustomerLocation();
      }
    }
  }, [customers, workOrderData]);

  useEffect(() => {
    getCustomers({
      search: customerSearch,
      pageSize: PAGE_SIZE_CUSTOMERS,
    });
  }, [getCustomers, customerSearch]);

  const customersOptions = (customers?.data || []).map((option) => ({
    label: option.customerName,
    value: option.id,
  }));

  return (
    <Col className="px-0">
      <Row className="px-0 justify-content-between d-flex mb-2">
        <FormGroup className="col-6">
          <Label>
            <span>Work Order #</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <input
            maxLength="100"
            type="text"
            className="form-control-redesign"
            placeholder="Enter the WO#"
            name="workOrderNumber"
            value={workOrderData.workOrderNumber || ""}
            onChange={(e) =>
              setWorkOrderData({
                ...workOrderData,
                workOrderNumber: e.target.value,
              })
            }
            required
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label>
            <span>Status</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <Select
            styles={style}
            placeholder="Select a status.."
            classNamePrefix="react-select"
            className="text-start bg-white px-0 react-select"
            options={authContext.userData.workOrderStatus?.map((status) => ({
              value: status.id,
              label: status.name,
            }))}
            closeMenuOnSelect={true}
            components={animatedComponents}
            value={selectedStatus}
            onChange={(selected) => {
              setWorkOrderData({
                ...workOrderData,
                workOrderStatusId: selected?.value,
              });
            }}
          />
        </FormGroup>
      </Row>
      <Row className="px-0 justify-content-between d-flex mb-2">
        <FormGroup className="col-6">
          <Label>
            <span>Customer</span>
          </Label>
          <Select
            isMulti={false}
            placeholder="Search for a customer.."
            data-testid="customer-select"
            noOptionsMessage={() => "No customers found"}
            styles={style}
            classNamePrefix="react-select"
            className="text-start bg-white px-0 react-select"
            options={customersOptions}
            closeMenuOnSelect={true}
            components={animatedComponents}
            isSearchable
            isClearable
            inputValue={customerSearch}
            value={selectedCustomer}
            onInputChange={setCustomerSearch}
            isLoading={isLoadingCustomers}
            onChange={(selected) => {
              setWorkOrderData({
                ...workOrderData,
                customerId: selected?.value,
              });
            }}
          />
        </FormGroup>
        {selectedCustomer ? (
          <FormGroup className="col-6 mb-0">
            <Label>
              <span>Customer Location</span>
            </Label>
            <Select
              isMulti={false}
              placeholder="Search for a location.."
              data-testid="customer-location-select"
              noOptionsMessage={() => "No locations found"}
              styles={style}
              classNamePrefix="react-select"
              className="text-start bg-white px-0 react-select"
              options={customerLocationsOptions}
              closeMenuOnSelect={true}
              components={animatedComponents}
              isSearchable
              isClearable
              inputValue={customerLocationSearch}
              value={selectedCustomerLocation}
              onInputChange={setCustomerLocationSearch}
              isLoading={isLoadingCustomers}
              onChange={(selected) => {
                setWorkOrderData({
                  ...workOrderData,
                  customerLocationId: selected?.value,
                });
              }}
            />
          </FormGroup>
        ) : null}
      </Row>
      <Row className="px-0 justify-content-between d-flex">
        <div className="d-flex col-12">
          <DatesSelector
            defaultStartDate={workOrderData.startDate}
            defaultEndDate={workOrderData.endDate}
            onSubmit={(startDate, endDate) => {
              setWorkOrderData({
                ...workOrderData,
                startDate,
                endDate,
              });
            }}
          />
        </div>
      </Row>
    </Col>
  );
};

export default WorkOrderForm;
