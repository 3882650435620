import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Card, CardHeader, CardBody, Container } from "reactstrap";

import CustomerLocationContactsModal from "../components/CustomerLocationContactsModal";

import { components, sharedHelper } from "@crewos/shared";

import { useNavigate } from "react-router-dom";

import { useGetCustomers } from "../api/Customers.hooks";
import CustomerBasicInfo from "../components/customerDetails/CustomerBasicInfo";
import CustomerContactInfo from "../components/customerDetails/CustomerContactInfo";
import CustomerWorkOrders from "../components/customerDetails/CustomerWorkOrders";
import CustomerLocations from "../components/customerDetails/CustomerLocations";

const { Loader } = components;

const CustomerDetails = () => {
  const { customerId } = useParams();

  const [customerData, setCustomerData] = useState();

  const [customerLocationContactsModal, setCustomerLocationContactsModal] =
    useState();

  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const {
    data: customer,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  useEffect(() => {
    getCustomers({ id: customerId });
  }, [getCustomers, customerId, refresh]);

  useEffect(() => {
    if (customer) {
      setCustomerData(customer);
    }
  }, [customer, setCustomerData]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/workorders");
    }
  };

  return (
    <Container fluid>
      <div className="w-100">
        <Card className="mb-3 w-100 box-shadow-none">
          <CardHeader className="pt-2 pb-3 d-flex px-0">
            <div className="d-flex col-12 px-0 px-2 text-dark">
              <div
                className="d-flex flex-column justify-content-center cursor-pointer"
                onClick={goBack}
                data-testid="back-button"
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  size="sm"
                  className="me-2 text-dark border rounded-circle back-arrow bg-white"
                />
              </div>
              <h2 className="mb-0">{customerData?.customerName}</h2>
            </div>
          </CardHeader>
          <CardBody className="p-4">
            {isLoadingCustomers ? (
              <Loader />
            ) : customerData ? (
              <div className="flex flex-column">
                <div className="d-flex flex-wrap">
                  <CustomerBasicInfo customer={customerData} />
                  <CustomerContactInfo customer={customerData} />
                </div>
                <div className="d-flex flex-wrap mt-4">
                  <CustomerLocations
                    customer={customerData}
                    setCustomerLocationContactsModal={
                      setCustomerLocationContactsModal
                    }
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                  <CustomerWorkOrders customer={customerData} />
                </div>
              </div>
            ) : (
              <div className="text-center">No results</div>
            )}
          </CardBody>
        </Card>
      </div>
      {customerLocationContactsModal ? (
        <CustomerLocationContactsModal
          customerLocation={customerLocationContactsModal}
          onClose={() => setCustomerLocationContactsModal()}
          onSubmit={() => {
            setCustomerLocationContactsModal();
            setRefresh((prev) => !prev);
            sharedHelper.successToast(`Location customer contacts saved`);
          }}
        />
      ) : null}
    </Container>
  );
};

export default CustomerDetails;
